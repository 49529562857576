import React from 'react'
import intl from 'react-intl-universal'
import './index.less'
import { Link, Route, withRouter } from 'react-router-dom'
import ProtocolItem from './components/ProtocolItem'
const Protocol = (props) => {
  const {
    location: { pathname },
  } = props

  return (
    <div className="protocol">
      <div style={{ height: '72px', width: '100%' }}></div>
      <div className="protocol_wrap">
        <div className="protocol_menu">
          <div
            className={
              pathname === '/protocol/terms'
                ? 'protocol_item active'
                : 'protocol_item'
            }
          >
            <Link to="/protocol/terms">{intl.get('Terms of Service')}</Link>
          </div>
          <div
            className={
              pathname === '/protocol/privacy'
                ? 'protocol_item active'
                : 'protocol_item'
            }
          >
            <Link to="/protocol/privacy">{intl.get('Privacy Policy')}</Link>
          </div>
        </div>
        <Route path="/protocol/terms" component={ProtocolItem} />
        <Route path="/protocol/privacy" component={ProtocolItem} />
      </div>
    </div>
  )
}

export default withRouter(Protocol)
