import React, { useEffect } from 'react'

import './index.less'
import wow from 'wowjs'
import Download from './components/download'
import Why from './components/why'

const MobileDownload = () => {
  useEffect(() => {
    setTimeout(() => {
      new wow.WOW().init()
    }, 300)
  }, [])
  return (
    <div className="download_block">
      <Download />
      <Why />
    </div>
  )
}

export default MobileDownload
