import Icon from '@ant-design/icons'
import React from 'react'

const safeSvg = () => (
  <svg width="64" height="72" xmlns="http://www.w3.org/2000/svg">
    <title>icon2</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g id="下载页">
          <g id="icon2">
            <path
              fill="#422CEA"
              id="jianguna"
              d="m32.01439,72l-1.16556,-0.3862c-2.7625,-0.89412 -5.52491,-1.7886 -7.82725,-2.68281c-11.05027,-3.5772 -23.02158,-7.60146 -23.02158,-22.35751l0,-34.43073c0,-1.34137 0.92068,-2.23549 2.30234,-2.23549c18.4169,0 28.08633,-9.39016 28.08633,-9.39016c0,0 0.60575,-0.5171 1.38171,-0.5171c0.77596,0 1.38079,0.5171 1.84131,0.96399c0,0 9.66898,9.39051 28.08597,9.39051c1.38157,0 2.30234,0.89412 2.30234,2.23548l0,33.9835c0,14.75605 -12.43156,18.78031 -23.02158,22.35751c-2.7625,0.89421 -5.52536,1.78869 -7.82725,2.68281l-1.13678,0.3862z"
            />
            <g id="编组-5">
              <path
                fill="#FFFFFF"
                id="形状结合"
                d="m31.99893,18l12.06907,10.114l4.932,0.00044c1.10457,0 2,0.89543 2,2l0,20.88556c0,1.10457 -0.89543,2 -2,2l-34,0c-1.10457,0 -2,-0.89543 -2,-2l0,-20.88556c0,-1.10457 0.89543,-2 2,-2l4.302,-0.00044l12.69693,-10.114zm-0.05593,4.4431l-7.119,5.6709l13.885,0l-6.766,-5.6709z"
              />
              <rect
                rx="1"
                height="3"
                width="14"
                y="34"
                x="20"
                fill="#422CEA"
                id="矩形"
              />
              <rect
                rx="1"
                height="3"
                width="24"
                y="39"
                x="20"
                fill="#422CEA"
                id="矩形备份"
              />
              <rect
                rx="1"
                height="3"
                width="24"
                y="44"
                x="20"
                fill="#422CEA"
                id="矩形备份-6"
              />
              <circle r="4.5" cy="21.5" cx="32.5" fill="#FFFFFF" id="椭圆形" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const safeIcon = (props) => <Icon component={safeSvg} {...props} />

export default safeIcon
