import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { Form, Button, message, Modal, Input } from 'antd'
import intl from 'react-intl-universal'
// import { DownOutlined } from '@ant-design/icons'
import CheckOutIcon from '@/icons/checkout'
import SuccessIcon from '@/icons/success'
// import CleanIcon from '@/icons/clean'
import ErrorIcon from '@/icons/error'
import WarningIcon from '@/icons/warnning'
import downIcon from '@/assets/sign/下拉 (1).svg'
// import upIcon from '@/assets/sign/收起.svg'
import * as reg from '@/utils/rules'
import areaList from '@/utils/area'
import { LangContext } from '@/reducer'
import { Link } from 'react-router-dom'
import MobileInput from 'mobile/component/MobileInput'
import http from '@/api'
import config from '@/utils/config'
import PropTypes from 'prop-types'
import './index.less'
import MobileToast from 'mobile/component/Toast'
let timer = null
const Toast = new MobileToast()
/**
 * 断网组件
 * @param {*} props
 */

const NetworkOnline = () => {
  // return <span>{intl.get('Please check your network')}</span>
  Toast.info(intl.get('Please check your network'))
}
const AreaSelect = (props) => {
  const handleExpand = () => {
    props.showSelect(true)
  }
  return (
    <div className="form_area_select" onClick={handleExpand}>
      <span className="select_val">+{props.value || '86'}</span>
      <img src={downIcon} alt="" />
    </div>
  )
}
AreaSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSelect: PropTypes.func,
}

const SendCode = (props) => {
  const [nowSend, setNowSend] = useState(false)
  const [time, setTime] = useState(60)
  const [btnText, setBtnText] = useState(0)
  const {
    state: { lang },
  } = useContext(LangContext)
  const sendStatus = {
    0: intl.get('Get code'),
    1: intl.get('Sending'),
    2: intl.get('s'),
  }

  const { areaCode, phone } = props
  useEffect(() => {
    if (time > 0 && time < 60) {
    } else {
      clearInterval(timer)
      setTime(60)
      setNowSend(false)
      setBtnText(0)
    }
  }, [time])
  const httpApi = lang === 'en-US' ? http.getOverseasSmsCode : http.getPhoneCode
  const handleSendCode = () => {
    if (!navigator.onLine) {
      // message.error({
      //   content: <NetworkOnline />,
      //   icon: <WarningIcon />,
      //   className: 'mobile_network_online',
      // })
      setTimeout(() => {
        Toast.info(intl.get('Please check your network'))
      })
      return
    }
    setBtnText(1)
    httpApi({
      areaCode: '+' + areaCode,
      phone,
    }).then((res) => {
      setBtnText(2)
      setNowSend(true)
      switch (+res.code) {
        case 0:
          timer = setInterval(() => {
            setTime((preTime) => {
              return --preTime
            })
          }, 1000)
          break
        case 70001:
          message.error(
            intl.get('Sending frequency is too fast, please try again later')
          )
          setBtnText(0)
          setNowSend(false)
          break
        case 20016:
          message.error(intl.get('Wrong mobile number'))
          setBtnText(0)
          setNowSend(false)
          break
        default:
          message.error(res.msg)
          setBtnText(0)
          setNowSend(false)
          break
      }
    })
  }

  return (
    <div className="web_send_code">
      <Button
        type="link"
        className="send_btn"
        disabled={props.disabled || nowSend}
        onClick={handleSendCode}
      >
        {nowSend && time}
        {sendStatus[btnText]}
      </Button>
    </div>
  )
}
SendCode.propTypes = {
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  areaCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
const PageResult = (props) => {
  // const [visibility, setVisibility] = useState('visible')
  // useEffect(() => {
  //   setTimeout(() => {
  //     setVisibility('hidden')
  //   }, 6000)
  // }, [])
  return (
    <div className="result_message">
      {props.icon}
      <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
    </div>
  )
}
PageResult.prototype = {
  icon: PropTypes.node,
  message: PropTypes.string,
}
export default function SignForm() {
  const [currentArea, setCurrentArea] = useState({
    value: 852,
    index: 6,
    reg: reg.hkPhoneReg,
  })
  const [form] = Form.useForm()
  // const [showSelect, setShowSelect] = useState(false)
  const [disabled, setDisabled] = useState(true)
  // const [focusInput, setFocusInput] = useState('')
  const [formData, setFormData] = useState({
    code: '',
    email: '',
    phone: '',
    userName: '',
  })
  const {
    state: { lang },
  } = useContext(LangContext)
  const areas = areaList[lang]
  areas.forEach((area, index) => {
    switch (area.countryCallingCode) {
      case '86':
        area.reg = reg.mainlandReg
        break
      case '852':
        area.reg = reg.hkPhoneReg
        break
      case '853':
        area.reg = reg.macaoPhoneReg
        break
      default:
        area.reg = reg.otherPhoneReg
        break
    }
  })
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [result, setResult] = useState({
    showResult: false,
    component: null,
  })
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  useLayoutEffect(() => {
    const top = window.scrollY
    if (visible) {
      // console.log(visible)
      document.body.style.position = 'fixed'
      document.body.style.top = -top + 'px'
    } else {
      console.log(visible)
      document.body.style.position = ''
      document.body.style.top = ''
      // document.querySelector('.ant-modal-wrap').style.position = ''
      // document.querySelector('.ant-modal-wrap').style.top = ''
      window.scrollTo(0, top)
    }
  }, [visible])
  useEffect(() => {
    // message.open({
    //   content: <NetworkOnline />,
    //   duration: 0,
    //   // top: 200,
    //   // icon: <WarningIcon />,
    //   className: 'mobile_network_online',
    // })
    // Toast.info('哈哈哈哈')

    // const mToast = new Toast()
    // mToast.info('哈哈哈', )
    // const networkText = intl.get('Please check your network')
    // setTimeout(() => {
    //   Toast.fail(
    //     intl.get('m_ok'),
    //     400000
    //   )
    // })
    const tempFormData = {
      code: formData.code,
      phone: formData.phone,
      userName: formData.userName,
    }
    const everyForm = Object.values(tempFormData).every((item) => Boolean(item))
    if (everyForm) {
      // console.log('useEffect', form)
      form
        .validateFields()
        .then(() => {
          setSubmitDisabled(false)
        })
        .catch((errorInfo) => {
          console.log('errorInfo', errorInfo)
          setSubmitDisabled(true)
        })
    }
  }, [formData])

  const onFinish = (values) => {
    if (!navigator.onLine) {
      // message.error({
      //   content: <NetworkOnline />,
      //   icon: <WarningIcon />,
      //   className: 'mobile_network_online',
      // })
      setTimeout(() => {
        Toast.info(intl.get('Please check your network'))
      })
      return
    }
    console.log(values)
    setLoading(true)
    http
      .postForeignCountry({ ...values, areaCode: '+' + currentArea.value })
      .then((res) => {
        switch (+res.code) {
          case 0:
            // setResult({
            //   showResult: true,
            //   component: () => (
            //     <PageResult
            //       icon={<SuccessIcon />}
            //       message={intl.get(
            //         'Sign up succeeded customer service will contact you ASAP'
            //       )}
            //     />
            //   ),
            // })
            setTimeout(() => {
              Toast.success(intl.get('m_ok'))
              setResult({
                showResult: false,
              })
            })
            form.resetFields()
            setDisabled(true)
            setSubmitDisabled(true)
            break
          case 20003:
            // setResult({
            //   showResult: true,
            //   component: () => (
            //     <PageResult
            //       icon={<ErrorIcon />}
            //       message={intl.get('verification code error')}
            //     />
            //   ),
            // })
            setTimeout(() => {
              Toast.fail(intl.get('m_code_error'))
              setResult({
                showResult: false,
              })
            })
            break
          case 20059:
            // setResult({
            //   showResult: true,
            //   component: () => (
            //     <PageResult
            //       icon={<ErrorIcon />}
            //       message={intl.get(
            //         'The mobile phone number has submitted data, please do not submit it again!'
            //       )}
            //     />
            //   ),
            // })
            setTimeout(() => {
              Toast.fail(intl.get('m_form_error'))
              setResult({
                showResult: false,
              })
            })
            form.resetFields()
            setDisabled(true)
            setSubmitDisabled(true)
            break
          case 20015:
            // setResult({
            //   showResult: true,
            //   component: () => (
            //     <PageResult
            //       icon={<ErrorIcon />}
            //       message={intl.get('Email format is incorrect')}
            //     />
            //   ),
            // })
            setTimeout(() => {
              Toast.fail(intl.get('Email format is incorrect'))
              setResult({
                showResult: false,
              })
            })
            break
          default:
            message.error(res.msg)
            break
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  /**
   * 当前选择项
   * @param {*} current
   * @param {*} index
   */
  const handleSelect = (current, index) => {
    return () => {
      console.log(current, index)
      const { countryCallingCode, reg } = current
      setCurrentArea({
        index,
        value: countryCallingCode,
        reg,
      })
      form.resetFields(['phone'])
      setDisabled(true)
      // expanded()
      setVisible(false)
    }
  }

  /**
   * 获取输入值
   * @param {*} changeValue
   * @param {*} allValue
   */
  const handleChangeValues = (changeValue, allValue) => {
    // console.log('handleChangeValues', changeValue, allValue)
    setFormData(allValue)
  }
  const validatorPhone = (rule, value) => {
    // setDisabled(true)
    if (currentArea.reg.test(value)) {
      setDisabled(false)
      return Promise.resolve()
    } else {
      // return Promise.resolve()
      setDisabled(true)
      return Promise.reject(intl.get('Mobile phone format is incorrect'))
    }
  }
  const handleUpdateStatus = (bool) => {
    setVisible(bool)
  }
  // const handleBlur = (fieldName) => {
  //   return (e) => {
  //     console.log(fieldName)
  //     console.log(formData[fieldName])
  //     if (formData[fieldName]) {
  //       setFocusInput(fieldName)
  //     } else {
  //       setFocusInput('')
  //     }
  //   }
  // }
  return (
    <div className="mobile_form">
      <h1>{intl.get('Welcome to Premier Q')}</h1>
      <Form
        layout="vertical"
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleChangeValues}
      >
        <Form.Item
          name="userName"
          rules={[
            {
              required: true,
              message: intl.get('Please enter name'),
            },
          ]}
        >
          <div className="form_item">
            {/* <Input
              bordered={false}
              placeholder={intl.get('Your Name')}
              maxLength={250}
              // suffix={focusInput === 'userName' && <CleanIcon />}
              // onInput={handleBlur('userName')}
            /> */}
            <MobileInput
              bordered={false}
              placeholder={intl.get('Your Name')}
              maxLength={250}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="phone"
          validateFirst
          rules={[
            // {
            //   required: true,
            //   message: intl.get('Mobile phone format is incorrect'),
            // },
            // {
            //   pattern: currentArea.reg,
            //   message: intl.get('Invalid verification code'),
            // },
            { validator: validatorPhone },
          ]}
        >
          <div className="form_phone form_item">
            <AreaSelect
              value={currentArea.value}
              showSelect={handleUpdateStatus}
            />
            <MobileInput
              type="number"
              bordered={false}
              placeholder={intl.get('Phone Number')}
              className="form_phone_input"
            />
            {/* <Input
              allowClear
              style={{ width: '80%' }}
              type="number"
              bordered={false}
              placeholder={intl.get('Phone Number')}
              className="form_phone_input"
            /> */}
            <Modal
              title={intl.get('Select region')}
              centered
              className="mobile_select_mask"
              closable={false}
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              width="90%"
              footer={null}
            >
              <div className="options_wrap">
                <ul className="options_list">
                  {areas.map((area, idx) => (
                    <li
                      className={
                        currentArea.index === idx
                          ? 'options_item active'
                          : 'options_item'
                      }
                      key={area.id}
                      onClick={handleSelect(area, idx)}
                    >
                      <span>
                        + {area.countryCallingCode}&nbsp;{area.name}
                      </span>
                      {currentArea.index === idx && <CheckOutIcon />}
                    </li>
                  ))}
                </ul>
              </div>
            </Modal>
          </div>
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            { required: true, message: intl.get('Invalid verification code') },
          ]}
        >
          <div className="form_item">
            <MobileInput
              bordered={false}
              placeholder={intl.get('Verification code')}
              suffix={
                <SendCode
                  disabled={disabled}
                  phone={formData.phone}
                  areaCode={currentArea.value}
                />
              }
            />
          </div>
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              pattern: reg.emailReg,
              message: intl.get('Email format is incorrect'),
            },
          ]}
        >
          <div className="form_item">
            {/* <Input
              allowClear
              bordered={false}
              placeholder={intl.get('Your Email')}
            /> */}
            <Input placeholder={intl.get('Your Email')} bordered={false} />
          </div>
        </Form.Item>
        <Form.Item className="form_btn">
          <Button
            block
            htmlType="submit"
            loading={loading}
            disabled={submitDisabled}
          >
            {intl.get('Submit')}
          </Button>
        </Form.Item>
      </Form>
      <p className="mobile_form_footer">
        {intl.get('Sign up means you agree to')} &nbsp;
        <Link to="/mobile/protocol?tagName=terms" className="form_footer_link">
          {intl.get('Terms of Service')}
        </Link>
        &nbsp;{intl.get('and')}&nbsp;
        <Link
          to="/mobile/protocol?tagName=privacy"
          className="form_footer_link"
        >
          {intl.get('Privacy Policy')}
        </Link>
      </p>
      <p className="live_chat">
        <a href={config.live}>{intl.get('home live chat')}</a>
      </p>
      {/* <a href="applinks://">打开QQ</a> */}
      {result.showResult && result.component()}
      {/* <div id="network-online"></div> */}
      {/* <PageResult
        icon={<SuccessIcon />}
        message={intl.get('verification code error')}
      /> */}
      {/* <NetworkOnline /> */}
    </div>
  )
}
