import React from 'react'
import { Redirect } from 'react-router-dom'
import MobileHome from 'mobile/views/Home'
import About from 'mobile/views/About'
import Protocol from 'mobile/views/Protocol'
import Download from 'mobile/views/Download'
import MobileLayout from 'mobile/layout'
import MobileSign from 'mobile/views/Sign'
export default [
  {
    component: MobileLayout,
    // render: () => <Redirect to={'/mobile'} />,
    routes: [
      {
        path: '/',
        exact: true,
        render: () => <Redirect to={'/mobile/home'} />,
      },
      {
        path: '/mobile/home',
        component: MobileHome,
      },
      {
        path: '/mobile/sign',
        component: MobileSign,
      },
      {
        path: '/mobile/about',
        component: About,
      },
      {
        path: '/mobile/protocol',
        component: Protocol,
      },
      {
        path: '/mobile/download',
        component: Download,
      },
    ],
  },
]
