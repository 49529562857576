import React from 'react'
import { renderRoutes } from 'react-router-config'
import LayoutHeader from '@/components/Header'
import LayoutFooter from '@/components/Footer'

const Layout = (props) => {
  const { route } = props

  // console.log(route)
  return (
    <div>
      <LayoutHeader />
      {renderRoutes(route.routes)}
      <LayoutFooter />
    </div>
  )
}

export default React.memo(Layout)
