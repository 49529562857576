import Icon from '@ant-design/icons'
import React from 'react'

const smileSvg = () => (
  <svg width="72" height="72" xmlns="http://www.w3.org/2000/svg">
    <title>icon4</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g id="下载页">
          <g id="icon4">
            <path
              fill="#422CEA"
              id="Fill-1"
              d="m35.99974,72c-4.86448,0 -9.57447,-0.95214 -14.01443,-2.82563c-4.28535,-1.81139 -8.1361,-4.40969 -11.44809,-7.71159c-3.31238,-3.30177 -5.90007,-7.152 -7.71146,-11.44796c-1.87362,-4.44061 -2.82576,-9.16005 -2.82576,-14.01508c0,-4.86448 0.95214,-9.57447 2.82576,-14.01443c1.81139,-4.28535 4.40969,-8.1361 7.71146,-11.44809c3.3019,-3.31238 7.15213,-5.90007 11.44809,-7.71146c4.43996,-1.87362 9.16005,-2.82576 14.01443,-2.82576c4.85503,0 9.57447,0.95214 14.01508,2.82576c4.28535,1.81139 8.13597,4.40969 11.44796,7.71146c3.31251,3.3019 5.9002,7.15213 7.71159,11.44809c1.87349,4.43996 2.82563,9.16005 2.82563,14.01443c0,4.86512 -0.95214,9.57447 -2.82563,14.01508c-1.81139,4.28535 -4.40969,8.13597 -7.71159,11.44796c-3.30177,3.31251 -7.152,5.9002 -11.44796,7.71159c-4.44061,1.87349 -9.16005,2.82563 -14.01508,2.82563z"
            />
            <path
              fill="#FFFFFF"
              id="形状结合"
              d="m52.41711,23.83603l4.0519,4.05191c0.39053,0.39052 0.39053,1.02368 0,1.41421l-14.4684,14.4689l6.50331,6.50321c0.39052,0.39053 0.39052,1.02369 0,1.41422c-0.18637,0.18637 -0.43878,0.29162 -0.70235,0.29288l-22.63284,0.1078c-0.55228,0.00263 -1.00213,-0.44295 -1.00476,-0.99523c-0.00127,-0.26686 0.10418,-0.52316 0.29289,-0.71187l26.54603,-26.54603c0.39053,-0.39053 1.02369,-0.39053 1.41422,0zm-3.82393,-4.83602c0.55228,-0.00263 1.00212,0.44295 1.00475,0.99523c0.00127,0.26686 -0.10418,0.52316 -0.29288,0.71187l-26.54603,26.54603c-0.39053,0.39053 -1.02369,0.39053 -1.41422,0l-4.05191,-4.05191c-0.39052,-0.39052 -0.39052,-1.02369 0,-1.41421l14.46841,-14.4689l-6.50331,-6.50321c-0.39053,-0.39053 -0.39053,-1.02369 0,-1.41422c0.18637,-0.18637 0.43878,-0.29162 0.70234,-0.29288l22.63285,-0.1078z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const SmileIcon = (props) => <Icon component={smileSvg} {...props}></Icon>

export default SmileIcon
