import Icon from '@ant-design/icons'
import React from 'react'

const successSvg = () => (
  <svg width="34" height="32" xmlns="http://www.w3.org/2000/svg">
    <title>成功</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fill="#422CEA" id="注册页-补充">
          <g id="编组备份-4">
            <g id="成功">
              <path
                fillRule="nonzero"
                id="选中"
                d="m16.26016,2.91126c-7.137,0.12824 -12.88263,5.93894 -12.88263,13.08874c0,7.23013 5.87547,13.09091 13.12372,13.09091c7.24825,0 13.12372,-5.86078 13.12372,-13.09091c0,-7.1498 -5.74563,-12.9605 -12.88263,-13.08874l-0.24109,-0.00217l-0.24109,0.00217zm0.50635,-2.90912c8.73657,0.14129 15.77484,7.24945 15.77484,15.99786c0,8.83678 -7.18117,16 -16.0401,16c-8.85892,0 -16.0401,-7.16322 -16.0401,-16c0,-8.74841 7.03827,-15.85657 15.77484,-15.99786l0.26526,-0.00214l0.26526,0.00214z"
              />
              <path
                id="路径"
                d="m23.69564,12.8972l-7.37203,7.3536l-1.13404,1.1312c-0.31358,0.3128 -0.82125,0.3128 -1.13484,0l-1.13403,-1.1312l-3.40291,-3.3936c-0.31278,-0.3128 -0.31278,-0.8192 0,-1.132l1.13404,-1.1312c0.31358,-0.3128 0.82205,-0.3128 1.13403,0l2.83669,2.8288l6.80502,-6.7888c0.31278,-0.312 0.82045,-0.312 1.13403,0l1.13404,1.1312c0.31358,0.3128 0.31358,0.8192 0,1.132"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const SuccessIcon = (props) => <Icon component={successSvg} {...props}></Icon>
export default SuccessIcon
