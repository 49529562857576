import React, { useContext } from 'react'
import './index.less'
import config from '@/utils/config'
import intl from 'react-intl-universal'
import { NavLink, Link } from 'react-router-dom'
import logo from '@/assets/img/footer_logo.svg'
import inIcon from '@/assets/img/in.svg'
import faceBookIcon from '@/assets/img/facebook.svg'
import twitterIcon from '@/assets/img/twitter.svg'
import insIcon from '@/assets/img/ins.svg'
import logoEn from '@/assets/home/logo_en_ft.svg'
import { LangContext } from '@/reducer'

const LayoutFooter = () => {
  const {
    state: { lang },
  } = useContext(LangContext)
  const icons = [
    {
      icon: inIcon,
      path: 'https://www.linkedin.com/company/69403666/admin/',
      name: 'in',
    },
    {
      icon: insIcon,
      path: 'https://www.instagram.com/premier.q.securities/',
      name: 'ins',
    },
    {
      icon: twitterIcon,
      path: 'https://twitter.com/premierqsecuri1',
      name: 'twitter',
    },
    {
      icon: faceBookIcon,
      path: 'https://www.facebook.com/premierqsecurities',
      name: 'facebook',
    },
  ]

  const footerInfo = [
    {
      label: 'Customer Service',
      value: '(852) 2111 2666',
    },
    {
      label: 'Fax number',
      value: '(852) 2111 2620',
    },
    {
      label: 'Email',
      value: 'cs@premierqhk.com',
    },
    {
      label: 'Office',
      value: 'OfficeRoom',
    },
    {
      label: 'Office hours',
      value: 'Monday to Friday 9:00-18:00 （GMT+8',
    },
  ]
  return (
    <div className="web_footer">
      <div className="footer_inner">
        <div className="footer_left">
          <ul>
            {config.footerMenu.map((menu, index) => (
              <li key={index}>
                <NavLink to={menu.path}>
                  <span>{intl.get(menu.name)}</span>
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="footer_logo">
            <Link to="/">
              <img src={lang === 'en-US' ? logoEn : logo} alt="logo" />
            </Link>
            {/* <a href="https://beian.miit.gov.cn/#/Integrated/index">
              <span>粤ICP备2020082115号</span>
            </a> */}
          </div>
        </div>
        <div className="footer_right">
          <ul className="footer_icons">
            {icons.map((icon, index) => (
              <li key={index}>
                <a href={icon.path} target="_blank" rel="noopener noreferrer">
                  <img src={icon.icon} alt={icon.name} />
                </a>
              </li>
            ))}
          </ul>
          <div className="footer_about">
            {footerInfo.map((item, index) => (
              <p key={index}>
                <span>{intl.get(item.label)}</span>
                <span>{intl.get(item.value)}</span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutFooter
