import React, { useContext } from 'react'
import './style/footer.less'
import { Link } from 'react-router-dom'
//:{ friendLink, services, footList }
import config from 'mobile/config'
import intl from 'react-intl-universal'
import { LangContext } from '@/reducer'
import globalConfig from '@/utils/config'
const enLogo = require('./img/logo.svg')

const zhLogo = require('@/assets/img/logo.svg')
const MobileFooter = () => {
  const {
    state: { lang },
  } = useContext(LangContext)
  const logo = lang === 'en-US' ? enLogo : zhLogo
  return (
    <div className="mobile_footer">
      <div className="footer_top">
        <div className="footer_nav">
          {config.footList.map((item) => (
            <Link to={item.path} key={item.text}>
              <span>{intl.get(item.text)}</span>
            </Link>
          ))}
        </div>
        <div className="footer_icon">
          {config.friendLink.map((item) => (
            <a href={item.path} key={item.name} target="_blank">
              <img src={item.icon} alt="" />
            </a>
          ))}
        </div>
      </div>
      <div className="live_chat">
        <a href={globalConfig.live}>
          <button>{intl.get('Live Chat')}</button>
        </a>
      </div>
      <div className="footer_service">
        {config.services.map((item, index) => (
          <p key={index}>
            <span>{intl.get(item.key)}</span>
            <span>{intl.get(item.text)}</span>
          </p>
        ))}
      </div>
      <Link to="/mobile/home">
        <img src={logo} alt="" className="f_logo" />
      </Link>
    </div>
  )
}

export default MobileFooter
