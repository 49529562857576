import Icon from '@ant-design/icons'
import React from 'react'

const cleanSvg = () => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <title>清除@2x</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fill="#B9BDCD" id="首页注册-补充">
          <g id="编组-11">
            <path
              id="清除"
              d="m13.9775,12.20937c0.24438,0.24438 0.24438,0.64001 0,0.88438l-0.88375,0.88375c-0.24437,0.24438 -0.64,0.24438 -0.88437,0l-2.20938,-2.21l-2.20938,2.21c-0.24437,0.24438 -0.64,0.24438 -0.88437,0l-0.88375,-0.88375c-0.24438,-0.24437 -0.24438,-0.64 0,-0.88438l2.21,-2.20937l-2.21,-2.20937c-0.24438,-0.24438 -0.24438,-0.64063 0,-0.88375l0.88375,-0.88438c0.24437,-0.24375 0.64,-0.24375 0.88437,0l2.20938,2.21l2.20938,-2.21c0.24437,-0.24375 0.64,-0.24375 0.88437,0l0.88375,0.88438c0.24438,0.24312 0.24438,0.63937 0,0.88375l-2.21,2.20937l2.21,2.20937zm-3.9775,-12.20937c-5.52312,0 -10,4.47688 -10,10c0,5.52312 4.47688,10 10,10c5.52312,0 10,-4.47688 10,-10c0,-5.52312 -4.47688,-10 -10,-10l0,0z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const cleanIcon = (props) => <Icon component={cleanSvg} {...props}></Icon>
export default cleanIcon
