import React, { useEffect, useContext } from 'react'
import { Image } from 'antd'
import intl from 'react-intl-universal'
import img1 from '@/assets/home/image1.svg'
import img2 from '@/assets/home/image2.svg'
import img3 from '@/assets/home/image3.svg'
import licenseImg from '@/assets/home/牌照@2x.png'
import { Link } from 'react-router-dom'
import SignForm from '@/pages/components/signForm'
import wow from 'wowjs'
import { LangContext } from '@/reducer'

import './index.less'
import './step2.less'
import './step3.less'
import './step4.less'
import step1BgEn from '@/assets/home/home_bg.png'
import step1BgEn2x from '@/assets/home/home_bg_2x.png'
import step1BgZh from '@/assets/home/home_bg_zh.png'
import step1BgZh2x from '@/assets/home/home_bg_zh_2x.png'

// import phone from '@/assets/home/phone@2x.png'

//第一屏
// const { Option } = Select

const PageStep1 = () => {
  const {
    state: { lang },
  } = useContext(LangContext)
  // console.log(window.devicePixelRatio)
  let bg = ''
  if (lang === 'en-US') {
    switch (window.devicePixelRatio) {
      case 1:
        bg = step1BgEn
        break
      case 2:
        bg = step1BgEn2x
        break
      default:
        bg = step1BgEn
    }
  } else {
    switch (window.devicePixelRatio) {
      case 1:
        bg = step1BgZh
        break
      case 2:
        bg = step1BgZh2x
        break
      default:
        bg = step1BgZh
    }
  }

  return (
    <div
      className="web_home_step1"
      style={{
        height: document.documentElement.clientHeight,
        backgroundImage: `url(${bg})`,
      }}
    >
      {/* <div style={{ height: '72px', width: '100%' }}></div> */}
      <div className="step1_content">
        <div className="left wow fadeInUp">
          <h1>{intl.get('Easy Simple Invest Globally')}</h1>
          <p>
            {intl.get(
              'Provide comprehensive brokerage services for a diversified customer base'
            )}
          </p>
          {/* <Button type="primary" shape="round" href="/sign">
            {intl.get('Sign Up')}
          </Button> */}
          <button>
            <Link to="/sign">{intl.get('Sign Up')}</Link>
          </button>
        </div>
        {/* <div className="right">
          <img src={phone} alt="" />
        </div> */}
      </div>
    </div>
  )
}
//第二屏
const PageStep2 = () => {
  const listText = [
    {
      icon: img1,
      texts: [
        intl.get('Easy to open an account'),
        intl.get('Super-low Commission'),
        intl.get('One account access to HK and US stocks'),
      ],
    },
    {
      icon: img2,
      texts: [
        intl.get('Lightening trading'),
        intl.get('Real-time market quotations'),
      ],
    },

    {
      icon: img3,
      texts: [
        intl.get('Professional analysts share investment strategies'),
        intl.get('Take you from the beginning of investment to mastery'),
      ],
    },
  ]
  return (
    <div className="step2_content">
      <h1 className="wow fadeInUp">
        {intl.get('Why Choose Premier Q Securities')}
      </h1>
      <div className="step2_list wow fadeInUp" data-wow-delay="0.3s">
        {listText.map((item, index) => (
          <div key={index} className="text_item">
            <img src={item.icon} alt={index} />
            <div className="step2_texts">
              {item.texts.map((text, idx) => (
                <p key={index + idx}>{text}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
//第三屏
const PageStep3 = () => {
  return (
    <div className="step3_content">
      <div className="step3_form">
        <div className="step3_left">
          <h1 className=" wow fadeInUp">{intl.get('Become our customers')}</h1>
          <div
            className="step3_left_content wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <p className="p_rc">
              {intl.get('Served 200,000+ Active Customers')}{' '}
            </p>
            <p className="p_rc">{intl.get('7 Years Experience')}</p>
            <p className="p_start">{intl.get('Start Trading Now')}</p>
          </div>
        </div>
        <div className="step3_right">
          <SignForm key="home_form" />
        </div>
      </div>
    </div>
  )
}
const PageStep4 = () => {
  // const previewType = {
  //   visible: true,
  //   getContainer: () => document.body, // V4.8.0
  // }
  return (
    <div className="step4_content">
      <div className="step4_left">
        <Image src={licenseImg} alt="牌照" />
      </div>
      <div className="step4_right">
        <h2 className="wow fadeInUp">{intl.get('licenseText')}</h2>
        <p className="wow fadeInUp" data-wow-delay="0.5s">
          {intl.get('licenseSubName')}
        </p>
      </div>
    </div>
  )
}
// let options = {
//   activeClass: 'active', // the class that is appended to the sections links
//   anchors: [], // the anchors for each sections
//   arrowNavigation: true, // use arrow keys
//   className: 'SectionContainer', // the class name for the section container
//   delay: 1000, // the scroll animation speed
//   navigation: true, // use dots navigatio
//   scrollBar: false, // use the browser default scrollbar
//   sectionClassName: 'Section', // the section class name
//   sectionPaddingTop: '0', // the section top padding
//   sectionPaddingBottom: '0', // the section bottom padding
//   verticalAlign: false, // align the content of each section vertical
// }
//
//鼠标事件
//

// const Fullpage = () => (
//   <ReactFullpage
//     //fullpage options
//     scrollingSpeed={1000} /* Options here */
//     scrollHorizontally={true} /* Because we are using the extension */
//     render={({ state, fullpageApi }) => {
//       return (
//         <ReactFullpage.Wrapper>
//           <div className="section">
//             <PageStep1 />
//           </div>
//           <div className="section">
//             <PageStep2 />
//           </div>
//           <div className="section">
//             <PageStep3 />
//           </div>
//           <div className="section">
//             <PageStep4 />
//           </div>
//         </ReactFullpage.Wrapper>
//       )
//     }}
//   />
// )
const Home = () => {
  // const [offsetheight, setOffsetheight] = useState(
  //   document.documentElement.clientHeight
  // )
  // const [top, setTop] = useState(0)
  // let i = 0
  // const [fullPage, setFullPage] = useState(0)
  // const [fullPageNum, setFullPageNum] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      new wow.WOW().init()
    }, 300)
    // window.addEventListener('mousewheel', (e) => {
    //   // window.scrollTo(0, offsetheight)
    //   if (e.wheelDelta < 0 && i < 3) {
    //     i++
    //   }
    //   if (e.wheelDelta > 0 && i > 0) {
    //     i--
    //   }
    //   setTimeout(function () {
    //     // setTop(-i * offsetheight + 'px')
    //   }, 0)
    // })
  }, [])
  return (
    <div className="web_home">
      {/* <Swiper {...options}>
        <SwiperSlide>
          <PageStep1 />
        </SwiperSlide>
        <SwiperSlide>
          <PageStep2 />
        </SwiperSlide>
        <SwiperSlide>
          <PageStep3 />
        </SwiperSlide>
        <SwiperSlide>
          <PageStep4 />
        </SwiperSlide>
      </Swiper> */}
      {/* <SectionsContainer {...options}>
        <Section>
          <PageStep1 />
        </Section>
        <Section>
          <PageStep2 />
        </Section>
        <Section>
          <PageStep3 />
        </Section>
        <Section>
          <PageStep4 />
        </Section>
      </SectionsContainer> */}
      {/* <div className="page_list"> */}
      <PageStep1 />
      <PageStep2 />
      <PageStep3 />
      <PageStep4 />
      {/* </div> */}
      {/* <Fullpage /> */}
    </div>
  )
}

export default Home
