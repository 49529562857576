import Icon from '@ant-design/icons'
import React from 'react'

const toneSvg = () => (
  <svg width="72" height="63" xmlns="http://www.w3.org/2000/svg">
    <title>icon3</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fillRule="nonzero" id="下载页">
          <g id="icon3">
            <path
              fill="#422CEA"
              id="T+0"
              d="m64.61538,0c4.07842,0 7.38462,3.58172 7.38462,8l0,47c0,4.41828 -3.3062,8 -7.38462,8l-57.23076,0c-4.07842,0 -7.38462,-3.58172 -7.38462,-8l0,-47c0,-4.41828 3.3062,-8 7.38462,-8l57.23076,0z"
            />
            <path
              fill="#FFFFFF"
              id="形状结合"
              d="m56,19c3.06,0 5.364,1.2965 6.948,3.95957c1.368,2.24259 2.052,5.25606 2.052,9.04043c0,3.78437 -0.684,6.79784 -2.052,9.04043c-1.584,2.62803 -3.888,3.95957 -6.948,3.95957c-3.096,0 -5.4,-1.33154 -6.948,-3.95957c-1.368,-2.24259 -2.052,-5.25606 -2.052,-9.04043c0,-3.78437 0.684,-6.79784 2.052,-9.04043c1.548,-2.66307 3.852,-3.95957 6.948,-3.95957zm-28,0c0.55228,0 1,0.44772 1,1l0,2c0,0.55228 -0.44772,1 -1,1l-8,0l0,21c0,0.55228 -0.44772,1 -1,1l-2,0c-0.55228,0 -1,-0.44772 -1,-1l0,-21l-8,0c-0.55228,0 -1,-0.44772 -1,-1l0,-2c0,-0.55228 0.44772,-1 1,-1l20,0zm8,7c0.55228,0 1,0.44772 1,1l0,5l5,0c0.55228,0 1,0.44772 1,1l0,2c0,0.55228 -0.44772,1 -1,1l-5,0l0,5c0,0.55228 -0.44772,1 -1,1l-2,0c-0.55228,0 -1,-0.44772 -1,-1l0,-5l-5,0c-0.55228,0 -1,-0.44772 -1,-1l0,-2c0,-0.55228 0.44772,-1 1,-1l5,0l0,-5c0,-0.55228 0.44772,-1 1,-1l2,0zm19.98516,-3c-1.89911,0 -3.26409,1.01661 -4.09495,3.07973c-0.59347,1.43522 -0.89021,3.40864 -0.89021,5.92027c0,2.48173 0.29674,4.45515 0.89021,5.92027c0.83086,2.03322 2.19584,3.07973 4.09495,3.07973c1.89911,0 3.2641,-1.04651 4.09496,-3.07973c0.59347,-1.46512 0.91988,-3.43854 0.91988,-5.92027c0,-2.51163 -0.32641,-4.48505 -0.91988,-5.92027c-0.83086,-2.06312 -2.19585,-3.07973 -4.09496,-3.07973z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const toneIcon = (props) => <Icon component={toneSvg} {...props} />
export default toneIcon
