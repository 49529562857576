import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Button, message } from 'antd'
import intl from 'react-intl-universal'
// import { DownOutlined } from '@ant-design/icons'
import CheckOutIcon from '@/icons/checkout'
import SuccessIcon from '@/icons/success'
// import CleanIcon from '@/icons/clean'
import ErrorIcon from '@/icons/error'
import WarningIcon from '@/icons/warnning'
import downIcon from '@/assets/sign/下拉 (1).svg'
import upIcon from '@/assets/sign/收起.svg'
import * as reg from '@/utils/rules'
import areaList from '@/utils/area'
import { LangContext } from '@/reducer'
import { Link } from 'react-router-dom'
import CleanInput from '@/components/CleanInput'
import http from '@/api'
import PropTypes from 'prop-types'
import './index.less'
let timer = null

/**
 * 断网组件
 * @param {*} props
 */

const NetworkOnline = () => {
  return <span>{intl.get('Please check your network')}</span>
}
const AreaSelect = (props) => {
  const handleExpand = () => {
    props.expanded()
  }
  return (
    <div className="form_area_select" onClick={handleExpand}>
      <span className="select_val">{props.value || '86'}</span>
      <img src={props.showSelect ? upIcon : downIcon} alt="" />
    </div>
  )
}
AreaSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSelect: PropTypes.bool,
}

const SendCode = (props) => {
  const [nowSend, setNowSend] = useState(false)
  const [time, setTime] = useState(60)
  const [btnText, setBtnText] = useState(0)
  const {
    state: { lang },
  } = useContext(LangContext)
  const sendStatus = {
    0: intl.get('Get code'),
    1: intl.get('Sending'),
    2: intl.get('s'),
  }

  const { areaCode, phone } = props
  useEffect(() => {
    if (time > 0 && time < 60) {
    } else {
      clearInterval(timer)
      setTime(60)
      setNowSend(false)
      setBtnText(0)
    }
  }, [time])
  const httpApi = lang === 'en-US' ? http.getOverseasSmsCode : http.getPhoneCode
  const handleSendCode = () => {
    if (!navigator.onLine) {
      message.error({
        content: <NetworkOnline />,
        icon: <WarningIcon />,
        className: 'network_online',
      })
      return
    }
    setBtnText(1)
    httpApi({
      areaCode: '+' + areaCode,
      phone,
    }).then((res) => {
      setBtnText(2)
      setNowSend(true)
      switch (+res.code) {
        case 0:
          timer = setInterval(() => {
            setTime((preTime) => {
              return --preTime
            })
          }, 1000)
          break
        case 70001:
          message.error(
            intl.get('Sending frequency is too fast, please try again later')
          )
          setBtnText(0)
          setNowSend(false)
          break
        case 20016:
          message.error(intl.get('Wrong mobile number'))
          setBtnText(0)
          setNowSend(false)
          break
        default:
          message.error(res.msg)
          setBtnText(0)
          setNowSend(false)
          break
      }
      // if (res.code === 0) {
      //   timer = setInterval(() => {
      //     setTime((preTime) => {
      //       return --preTime
      //     })
      //   }, 1000)
      // } else {
      //   message.error(res.msg)
      //   setBtnText(0)
      //   setNowSend(false)
      // }
    })
  }

  return (
    <div className="web_send_code">
      <Button
        type="link"
        className="send_btn"
        disabled={props.disabled || nowSend}
        onClick={handleSendCode}
      >
        {nowSend && time}
        {sendStatus[btnText]}
      </Button>
    </div>
  )
}
SendCode.propTypes = {
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  areaCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
const PageResult = (props) => {
  // const [visibility, setVisibility] = useState('visible')
  // useEffect(() => {
  //   setTimeout(() => {
  //     setVisibility('hidden')
  //   }, 6000)
  // }, [])
  return (
    <div className="result_message">
      {props.icon}
      <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
    </div>
  )
}
PageResult.prototype = {
  icon: PropTypes.node,
  message: PropTypes.string,
}
export default function SignForm() {
  const [currentArea, setCurrentArea] = useState({
    value: 852,
    index: 6,
    reg: reg.hkPhoneReg,
  })
  const [form] = Form.useForm()
  const [showSelect, setShowSelect] = useState(false)
  const [disabled, setDisabled] = useState(true)
  // const [focusInput, setFocusInput] = useState('')
  const [formData, setFormData] = useState({
    code: '',
    email: '',
    phone: '',
    userName: '',
  })
  const {
    state: { lang },
  } = useContext(LangContext)
  const areas = areaList[lang]
  areas.forEach((area, index) => {
    switch (area.countryCallingCode) {
      case '86':
        area.reg = reg.mainlandReg
        break
      case '852':
        area.reg = reg.hkPhoneReg
        break
      case '853':
        area.reg = reg.macaoPhoneReg
        break
      default:
        area.reg = reg.otherPhoneReg
        break
    }
  })
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [result, setResult] = useState({
    showResult: false,
    component: null,
  })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const tempFormData = {
      code: formData.code,
      phone: formData.phone,
      userName: formData.userName,
    }
    const everyForm = Object.values(tempFormData).every((item) => Boolean(item))
    if (everyForm) {
      // console.log('useEffect', form)
      form
        .validateFields()
        .then(() => {
          setSubmitDisabled(false)
        })
        .catch((errorInfo) => {
          console.log('errorInfo', errorInfo)
          setSubmitDisabled(true)
        })
    }
  }, [formData])
  // useLayoutEffect(() => {
  //   // const clearIcons = document.getElementsByClassName('ant-input-clear-icon')
  //   // clearIcons.forEach((n) => {
  //   //   n.innerHTML = ''
  //   // })
  //   console.log(document.getElementsByClassName('ant-input-clear-icon'))
  // }, [formData])
  // const options = [
  //   {
  //     value: '86',
  //     text: `+86 ${intl.get('Taiwan, China')}`,
  //     reg: reg.mainlandReg,
  //   },
  //   {
  //     value: '52',
  //     text: `+52 ${intl.get('Hong Kong，China')}`,
  //     reg: reg.hkPhoneReg,
  //   },
  //   {
  //     value: '53',
  //     text: `+53 ${intl.get('Macao, China')}`,
  //     reg: reg.macaoPhoneReg,
  //   },
  //   {
  //     value: '63',
  //     text: `+63 ${intl.get('Philippines')}`,
  //     reg: reg.otherPhoneReg,
  //   },
  //   {
  //     value: '60',
  //     text: `+60 ${intl.get('Malaysia')}`,
  //     reg: reg.otherPhoneReg,
  //   },
  // ]
  const onFinish = (values) => {
    if (!navigator.onLine) {
      message.error({
        content: <NetworkOnline />,
        icon: <WarningIcon />,
        className: 'network_online',
      })
      return
    }
    console.log(values)
    setLoading(true)
    http
      .postForeignCountry({ ...values, areaCode: '+' + currentArea.value })
      .then((res) => {
        switch (+res.code) {
          case 0:
            setResult({
              showResult: true,
              component: () => (
                <PageResult
                  icon={<SuccessIcon />}
                  message={intl.get(
                    'Sign up succeeded customer service will contact you ASAP'
                  )}
                />
              ),
            })
            setTimeout(() => {
              setResult({
                showResult: false,
              })
            }, 6000)
            form.resetFields()
            setDisabled(true)
            break
          case 20003:
            setResult({
              showResult: true,
              component: () => (
                <PageResult
                  icon={<ErrorIcon />}
                  message={intl.get('verification code error')}
                />
              ),
            })
            setTimeout(() => {
              setResult({
                showResult: false,
              })
            }, 6000)
            break
          case 20059:
            setResult({
              showResult: true,
              component: () => (
                <PageResult
                  icon={<ErrorIcon />}
                  message={intl.get(
                    'The mobile phone number has submitted data, please do not submit it again!'
                  )}
                />
              ),
            })
            setTimeout(() => {
              setResult({
                showResult: false,
              })
            }, 6000)
            form.resetFields()
            setDisabled(true)
            break
          default:
            message.error(res.msg)
            break
        }
        // if (+res.code === 0) {
        //   setResult({
        //     showResult: true,
        //     component: () => (
        //       <PageResult
        //         icon={<SuccessIcon />}
        //         message={intl.get(
        //           'Sign up succeeded customer service will contact you ASAP'
        //         )}
        //       />
        //     ),
        //   })
        //   setTimeout(() => {
        //     setResult({
        //       showResult: false,
        //     })
        //   }, 3000)
        //   form.resetFields()
        //   setDisabled(true)
        // } else if (+res.code === 20003) {
        //   // message.error(intl.get('verification code error'))
        //   setResult({
        //     showResult: true,
        //     component: () => (
        //       <PageResult
        //         icon={<ErrorIcon />}
        //         message={intl.get('verification code error')}
        //       />
        //     ),
        //   })
        //   setTimeout(() => {
        //     setResult({
        //       showResult: false,
        //     })
        //   }, 3000)
        // } else {
        //   message.error(res.msg)
        // }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  /**
   * 当前选择项
   * @param {*} current
   * @param {*} index
   */
  const handleSelect = (current, index) => {
    return () => {
      console.log(current, index)
      const { countryCallingCode, reg } = current
      setCurrentArea({
        index,
        value: countryCallingCode,
        reg,
      })
      form.resetFields(['phone'])
      setDisabled(true)
      expanded()
    }
  }
  const expanded = () => {
    setShowSelect(!showSelect)
  }
  /**
   * 获取输入值
   * @param {*} changeValue
   * @param {*} allValue
   */
  const handleChangeValues = (changeValue, allValue) => {
    // console.log('handleChangeValues', changeValue, allValue)
    setFormData(allValue)
  }
  const validatorPhone = (rule, value) => {
    if (currentArea.reg.test(value)) {
      setDisabled(false)
      return Promise.resolve()
    } else {
      // return Promise.resolve()
      setDisabled(true)
      return Promise.reject(intl.get('Mobile phone format is incorrect'))
    }
  }
  // const handleBlur = (fieldName) => {
  //   return (e) => {
  //     console.log(fieldName)
  //     console.log(formData[fieldName])
  //     if (formData[fieldName]) {
  //       setFocusInput(fieldName)
  //     } else {
  //       setFocusInput('')
  //     }
  //   }
  // }
  return (
    <div className="web_form">
      <Form
        layout="vertical"
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleChangeValues}
      >
        <Form.Item
          label={intl.get('Name')}
          name="userName"
          rules={[
            {
              required: true,
              message: intl.get('Please enter name'),
            },
          ]}
        >
          <div className="form_item">
            {/* <Input
              bordered={false}
              placeholder={intl.get('Your Name')}
              maxLength={250}
              // suffix={focusInput === 'userName' && <CleanIcon />}
              // onInput={handleBlur('userName')}
            /> */}
            <CleanInput
              bordered={false}
              placeholder={intl.get('Your Name')}
              maxLength={250}
            />
          </div>
        </Form.Item>

        <Form.Item
          label={intl.get('Phone')}
          name="phone"
          validateFirst
          rules={[
            {
              required: true,
              message: intl.get('Mobile phone format is incorrect'),
            },
            // {
            //   pattern: currentArea.reg,
            //   message: intl.get('Invalid verification code'),
            // },
            { validator: validatorPhone },
          ]}
        >
          <div className="form_phone form_item">
            <AreaSelect
              value={currentArea.value}
              expanded={expanded}
              showSelect={showSelect}
            />
            <CleanInput
              type="number"
              bordered={false}
              placeholder={intl.get('Phone Number')}
              className="form_phone_input"
            />
            {/* <Input
              allowClear
              style={{ width: '80%' }}
              type="number"
              bordered={false}
              placeholder={intl.get('Phone Number')}
              className="form_phone_input"
            /> */}
            {showSelect && (
              <div className="options_wrap">
                <ul className="options_list">
                  {areas.map((area, idx) => (
                    <li
                      className={
                        currentArea.index === idx
                          ? 'options_item active'
                          : 'options_item'
                      }
                      key={area.id}
                      onClick={handleSelect(area, idx)}
                    >
                      <span>
                        + {area.countryCallingCode}&nbsp;{area.name}
                      </span>
                      {currentArea.index === idx && <CheckOutIcon />}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Form.Item>
        <Form.Item
          label={intl.get('Verification code')}
          name="code"
          rules={[
            { required: true, message: intl.get('Invalid verification code') },
          ]}
        >
          <div className="form_item">
            {/* <CleanInput
              bordered={false}
              placeholder={intl.get('Verification code')}
              suffix={
                <SendCode
                  disabled={disabled}
                  phone={formData.phone}
                  areaCode={currentArea.value}
                />
              }
            /> */}
            <Input
              bordered={false}
              placeholder={intl.get('Verification code')}
              suffix={
                <SendCode
                  disabled={disabled}
                  phone={formData.phone}
                  areaCode={currentArea.value}
                />
              }
            />
          </div>
        </Form.Item>
        <Form.Item
          label={intl.get('Email')}
          name="email"
          rules={[
            {
              pattern: reg.emailReg,
              message: intl.get('Email format is incorrect'),
            },
          ]}
        >
          <div className="form_item">
            {/* <Input
              allowClear
              bordered={false}
              placeholder={intl.get('Your Email')}
            /> */}
            <CleanInput placeholder={intl.get('Your Email')} bordered={false} />
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            block
            htmlType="submit"
            loading={loading}
            className="form_submit_btn"
            disabled={submitDisabled}
          >
            {intl.get('Submit')}
          </Button>
        </Form.Item>
      </Form>
      <p className="form_footer">
        {intl.get('Sign up means you agree to')} &nbsp;
        <Link to="/protocol/terms" className="form_footer_link">
          {intl.get('Terms of Service')}
        </Link>
        &nbsp;{intl.get('and')}&nbsp;
        <Link to="/protocol/privacy" className="form_footer_link">
          {intl.get('Privacy Policy')}
        </Link>
      </p>
      {result.showResult && result.component()}
      <div id="network-online"></div>
      {/* <PageResult
        icon={<ErrorIcon />}
        message={intl.get('verification code error')}
      /> */}
      {/* <NetworkOnline /> */}
    </div>
  )
}
