import Icon from '@ant-design/icons'
import React from 'react'

const errorSvg = () => (
  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
    <title>成功</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fill="#F35353" id="首页注册-补充">
          <g id="编组备份-4">
            <g id="成功">
              <path
                fillRule="nonzero"
                id="选中"
                d="m15.75951,2.91126c-7.11916,0.12824 -12.85042,5.93894 -12.85042,13.08874c0,7.23013 5.86078,13.09091 13.09091,13.09091c7.23013,0 13.09091,-5.86078 13.09091,-13.09091c0,-7.1498 -5.73126,-12.9605 -12.85042,-13.08874l-0.24049,-0.00217l-0.24049,0.00217zm0.50509,-2.90912c8.71473,0.14129 15.7354,7.24945 15.7354,15.99786c0,8.83678 -7.16322,16 -16,16c-8.83678,0 -16,-7.16322 -16,-16c0,-8.74841 7.02067,-15.85657 15.7354,-15.99786l0.2646,-0.00214l0.2646,0.00214z"
              />
              <path
                id="形状结合"
                d="m13.62635,11.29289l2.86365,2.86311l2.86391,-2.86311c0.39052,-0.39052 1.02369,-0.39052 1.41421,0l0.91924,0.91924c0.39053,0.39053 0.39053,1.02369 0,1.41422l-2.86336,2.86365l2.86336,2.86391c0.39053,0.39052 0.39053,1.02369 0,1.41421l-0.91924,0.91924c-0.39052,0.39053 -1.02369,0.39053 -1.41421,0l-2.86391,-2.86336l-2.86365,2.86336c-0.39053,0.39053 -1.02369,0.39053 -1.41422,0l-0.91924,-0.91924c-0.39052,-0.39052 -0.39052,-1.02369 0,-1.41421l2.86311,-2.86391l-2.86311,-2.86365c-0.39052,-0.39053 -0.39052,-1.02369 0,-1.41422l0.91924,-0.91924c0.39053,-0.39052 1.02369,-0.39052 1.41422,0z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const errorIcon = (props) => <Icon component={errorSvg} {...props} />

export default errorIcon
