// lang.js
import React, { createContext, useReducer } from 'react'

// 创建 context
export const LangContext = createContext({})

// 创建 store

// const reducer = (state, action) => {
//   switch (action.type) {
//     case UPDATE_LANG:
//       return action.lang
//     default:
//       return state
//   }
// }
export const UPDATE_LANG = 'UPDATE_LANG'
export const Lang = (props) => {
  // const [lang, dispatch] = useReducer(reducer, 'en-US')

  const [state, dispatch] = useReducer(
    (state, action) => {
      console.log(action)
      switch (action.type) {
        case UPDATE_LANG:
          return { ...state, lang: action.lang }
        default:
          throw new Error()
      }
    },
    { lang: localStorage.getItem('lang_type') || 'en-US' }
  )
  return (
    <LangContext.Provider value={{ state, dispatch }}>
      {props.children}
    </LangContext.Provider>
  )
}
