import React, { Component } from 'react'
import { Input } from 'antd'
import CleanIcon from '@/icons/clean'
import './index.less'
export default class CleanInput extends Component {
  state = {
    value: '',
    hidden: true,
  }
  handleChange = (e) => {
    this.setState(
      {
        value: e.target.value,
      },
      () => {
        if (this.state.value) {
          this.setState({
            hidden: false,
          })
        } else {
          this.setState({
            hidden: true,
          })
        }
      }
    )
  }
  handleBlur = () => {
    this.setState({
      hidden: true,
    })
  }
  handleFocus = () => {
    if (this.state.value) {
      this.setState({
        hidden: false,
      })
    }
  }
  handleClean = (e) => {
    this.setState({
      value: '',
      hidden: true,
    })
  }
  handleMousedown = (e) => {
    e.preventDefault()
  }
  render() {
    return (
      <div className="web_input">
        <Input
          {...this.props}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        ></Input>
        <CleanIcon
          onClick={this.handleClean}
          className="input_clean_icon"
          hidden={this.state.hidden}
          onMouseDown={this.handleMousedown}
        />
      </div>
    )
  }
}

// const CleanInput = (props) => {
//   return (
//     <div className="web_input">
//       <Input {...props} ></Input>
//       <CleanIcon className="input_clean_icon" />
//     </div>
//   )
// }

// export default CleanInput
