import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
// import wow from 'wowjs'
import 'animate.css'
import 'core-js/es'

import 'react-app-polyfill/ie9'
import 'lib-flexible'
import 'react-app-polyfill/stable'
import './reset.css'
// new wow.WOW().init()
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
