import request from '../utils/request'
import config from '@/config'
const api = '/api/user-service'
console.log('config', config)
/**
 * 获取手机号码验证码
 * @param {*} params
 */
const getPhoneCode = (params) =>
  request({
    url: config.baseUrl + api + '/v1/user/officeSite/codeByPhone',
    method: 'POST',
    data: params,
  })
/**
 * 提交海外官网信息
 * @param {*} params
 */
const postForeignCountry = (params) =>
  request({
    url: config.baseUrl + api + '/v1/user/officeSite/foreignCountry',
    method: 'POST',
    data: params,
  })

const getOverseasSmsCode = (params) =>
  request({
    url: config.baseUrl + api + '/v1/user/foreignOfficeSite/codeByPhone',
    method: 'POST',
    data: params,
  })
export default {
  getPhoneCode,
  postForeignCountry,
  getOverseasSmsCode,
}
