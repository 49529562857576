import Icon from '@ant-design/icons'
import React from 'react'

const googleSvg = () => (
  <svg
    width="29"
    height="31"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <title>google-play</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fill="currentColor" id="首页">
          <g id="background1">
            <g id="编组-20备份">
              <g id="编组-8">
                <path
                  id="google-play"
                  d="m18.93163,14.18888l-13.33581,-13.40158l16.96734,9.762l-3.63153,3.63958zm-16.81628,-14.18888c-0.78541,0.41176 -1.31118,1.16269 -1.31118,2.13772l0,26.72456c0,0.97503 0.52577,1.72596 1.31118,2.13772l15.5051,-15.50297l-15.5051,-15.49703zm25.69265,13.6621l-3.55895,-2.06517l-3.96995,3.90619l3.96995,3.90601l3.63154,-2.06517c1.08768,-0.86596 1.08768,-2.81591 -0.07259,-3.68186zm-22.21218,16.55665l16.96734,-9.762l-3.63153,-3.63958l-13.33581,13.40158z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const GoogleIcon = (props) => <Icon component={googleSvg} {...props} />

export default GoogleIcon
