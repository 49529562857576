import Icon from '@ant-design/icons'
import React from 'react'
const linedSvg = () => (
  <svg width="64" height="72" xmlns="http://www.w3.org/2000/svg">
    <title>icon1</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g id="下载页">
          <g id="icon1">
            <path
              fill="#422CEA"
              id="touzi"
              d="m59.62787,14.72499c-0.17405,-0.12857 -0.36203,-0.24282 -0.56158,-0.33933l-22.15306,-12.96174l-0.64871,-0.38425c-2.75476,-1.41105 -5.97383,-1.39113 -8.6903,0.09245l-22.70614,13.28519l-0.47158,0.28329c-2.75312,1.72319 -4.3965,4.71587 -4.3965,8.00607l0,26.59674c0,3.22658 1.59618,6.1872 4.33937,7.96268l22.74494,13.30709c0.11078,0.07503 0.22197,0.13988 0.34033,0.20629l0.13125,0.07669c1.3522,0.74827 2.88868,1.14384 4.4437,1.14384c1.55614,0 3.09068,-0.39557 4.44175,-1.14384l0.19392,-0.1184c0.06563,-0.03933 0.13126,-0.07824 0.19678,-0.11996l22.17149,-12.96745l0.74986,-0.44797c2.61644,-1.71737 4.20535,-4.62217 4.24661,-7.80081l0,-26.71234c0,-3.2327 -1.67174,-6.27157 -4.37213,-7.96424l0,0z"
            />
            <path
              fill="#FFFFFF"
              id="形状结合"
              d="m20.87523,38.23529c0.55228,0 1,0.44772 1,1l0,10.76471c0,0.55228 -0.44772,1 -1,1l-2,0c-0.55229,0 -1,-0.44772 -1,-1l0,-10.76471c0,-0.55228 0.44771,-1 1,-1l2,0zm12.1874,0c0.55228,0 1,0.44772 1,1l0,10.76471c0,0.55228 -0.44772,1 -1,1l-2,0c-0.55229,0 -1,-0.44772 -1,-1l0,-10.76471c0,-0.55228 0.44771,-1 1,-1l2,0zm12.1874,-4.55882c0.55228,0 1,0.44772 1,1l0,15.32353c0,0.55228 -0.44772,1 -1,1l-2,0c-0.55228,0 -1,-0.44772 -1,-1l0,-15.32353c0,-0.55228 0.44772,-1 1,-1l2,0zm-0.4044,-13.83084c0.55229,0 1,0.44772 1,1l0,8c0,0.55229 -0.44771,1 -1,1l-2,0c-0.55228,0 -1,-0.44771 -1,-1l0.00027,-1.39235c-6.07353,5.78575 -13.6246,8.54289 -22.54261,8.2219c-1.10386,-0.03974 -1.9665,-0.9668 -1.92677,-2.07065c0.03973,-1.10385 0.96679,-1.9665 2.07065,-1.92677c8.19315,0.29491 14.93804,-2.29664 20.36026,-7.83085l-2.9618,-0.00128c-0.55228,0 -1,-0.44771 -1,-1l0,-2c0,-0.55228 0.44772,-1 1,-1l8,0z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const LinedIcon = (props) => <Icon component={linedSvg} {...props} />

export default LinedIcon
