import React from 'react'
import { Input } from 'antd'
import './index.less'
import PropTypes from 'prop-types'
export default function MobileInput(props) {
  return (
    <div className="mobile_input">
      <Input {...props} autoComplete="off" />
    </div>
  )
}

// MobileInput
