import React from 'react'
import GoogleIcon from '@/icons/google'
import AppIcon from '@/icons/app'
import qrImg from '@/assets/home/二维码@2x.png'
import { Button } from 'antd'
import './index.less'
import config from '@/utils/config'
export default function QrCodeStore() {
  // const handleMouseOver = () => {}
  return (
    <div className="web_qrcode">
      <img src={qrImg} alt="二维码" />
      <div className="web_btn">
        <Button
          shape="round"
          icon={<GoogleIcon />}
          className="link_btn"
          // onMouseOver={handleMouseOver}
        >
          <a href={config.android} target="_blank" rel="noopener noreferrer">
            {'Google Play'}
          </a>
        </Button>
        <Button
          target="_blank"
          shape="round"
          icon={<AppIcon />}
          className="link_btn"
        >
          <a href={config.ios} rel="noopener noreferrer">
            {'App Store'}
          </a>
        </Button>
      </div>
    </div>
  )
}
