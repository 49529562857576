import React, { useLayoutEffect } from 'react'
import SignForm from '@/pages/components/signForm'
import './index.less'
import signImg from '@/assets/sign/注册图@2x.png'
import intl from 'react-intl-universal'

const PageStep1 = () => {
  useLayoutEffect(() => {
    // console.log(document.documentElement.clientHeight)
    const viewHeight =
      document.body.clientHeight || document.documentElement.clientHeight
    const signBlock = document.getElementsByClassName('sign_right')[0]
    if (viewHeight <= 800) {
      signBlock.style.padding = '15px 56px'
    }
    // )
  }, [])
  return (
    <div className="sign_step1">
      <div style={{ height: '72px', background: 'transparent' }}></div>
      <div className="step1_content">
        <div className="sign_left">
          <img src={signImg} alt="" />
        </div>
        <div className="sign_right">
          <h1>{intl.get('Welcome to Premier Q')}</h1>
          <SignForm />
        </div>
      </div>
    </div>
  )
}

export default function Sign() {
  return (
    <div className="sign">
      <PageStep1 />
    </div>
  )
}
