import React, { useEffect, useState, useMemo } from 'react'

import './index.less'
import { pathToObject } from '@/utils/util'
import Terms from './components/terms'
import Pribacy from './components/privacy'

const MobileProtocol = (props) => {
  const [tagName, setTagName] = useState(null)
  const components = useMemo(() => {
    return {
      terms: Terms,
      privacy: Pribacy,
    }
  }, [])
  const TagName = useMemo(() => {
    return components[tagName] || null
  }, [tagName])

  useEffect(() => {
    let {
      location: { search },
    } = props
    search = search.substr(1)
    console.log(search)
    const { tagName } = pathToObject(search) || ''
    setTagName(tagName)
  }, [])

  return (
    <div className="protocol_block">
      <div className="protocol_head"></div>
      <div className="protocol_content">{TagName && <TagName />}</div>
    </div>
  )
}

export default MobileProtocol
