import React, { useContext, useEffect } from 'react'
import './index.less'
import { Link } from 'react-router-dom'
import intl from 'react-intl-universal'
import img1 from '@/assets/home/image1.svg'
import img2 from '@/assets/home/image2.svg'
import img3 from '@/assets/home/image3.svg'

import { LangContext } from '@/reducer'
import wow from 'wowjs'
import hkGif from './img/hk.gif'
import usGif from './img/us.gif'
import licenseImg from './img/paizhao.png'
const HomePage1 = () => {
  return (
    <div className="mobile_home_page1">
      <div className="home_page1_head wow fadeInUp">
        <h1>{intl.get('Easy Simple Invest Globally')}</h1>
        <p>
          {intl.get(
            'Provide comprehensive brokerage services for a diversified customer base'
          )}
        </p>
      </div>
      <div className="home_page1_btn ">
        <Link to="/mobile/sign">
          <button>{intl.get('Sign Up')}</button>
        </Link>
      </div>
    </div>
  )
}
const HomePage2 = () => {
  const listText = [
    {
      icon: img1,
      texts: [
        intl.get('Easy to open an account'),
        intl.get('Super-low Commission'),
        intl.get('One account access to HK and US stocks'),
      ],
    },
    {
      icon: img2,
      texts: [
        intl.get('Lightening trading'),
        intl.get('Real-time market quotations'),
      ],
    },

    {
      icon: img3,
      texts: [
        intl.get('Professional analysts share investment strategies'),
        intl.get('Take you from the beginning of investment to mastery'),
      ],
    },
  ]
  return (
    <div className="home_page2">
      <h1 className="wow fadeInUp">
        {' '}
        {intl.get('Why Choose Premier Q Securities')}
      </h1>
      <div className="home_page2_content wow fadeInUp">
        {listText.map((item, index) => (
          <div key={index} className="text_item">
            <img src={item.icon} alt={index} />
            <div className="step2_texts">
              {item.texts.map((text, idx) => (
                <p
                  key={index + idx}
                  dangerouslySetInnerHTML={{ __html: text }}
                ></p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const HomePage3 = () => {
  const {
    state: { lang },
  } = useContext(LangContext)
  const src = lang === 'en-US' ? usGif : hkGif
  return (
    <div className="home_page3">
      <div className="page3_head wow fadeInUp">
        <h1>{intl.get('Mobile Trading Platform')}</h1>
        <p>{intl.get('Make investment easier through technology')}</p>
      </div>
      <div className="page3_content">
        <img src={src} alt="" />
      </div>
      <div className="page3_footer wow fadeInUp">
        <Link to="/mobile/download">
          <button>{intl.get('mobileHomeDown')}</button>
        </Link>
      </div>
    </div>
  )
}

const HomePage4 = () => {
  return (
    <div className="home_page4 ">
      <div className="page4_head wow fadeInUp">
        <h1>{intl.get('licenseText')}</h1>
        <p>{intl.get('licenseSubName')}</p>
      </div>
      <img src={licenseImg} alt="" />
    </div>
  )
}

export default function MobileHome() {
  useEffect(() => {
    setTimeout(() => {
      new wow.WOW().init()
    }, 300)
  }, [])
  return (
    <div className="mobile_home">
      <HomePage1 />
      <HomePage2 />
      <HomePage3 />
      <HomePage4 />
      {/* <FooterShop /> */}
    </div>
  )
}
