import Icon from '@ant-design/icons'
import React from 'react'

const warningSvg = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <title>提示</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fill="#FBA854" id="首页注册-补充">
          <g id="编组备份-7">
            <g id="提示">
              <path
                fillRule="nonzero"
                id="选中"
                d="m11.81963,2.18344c-5.33937,0.09618 -9.63781,4.45421 -9.63781,9.81656c0,5.4226 4.39558,9.81818 9.81818,9.81818c5.4226,0 9.81818,-4.39558 9.81818,-9.81818c0,-5.36235 -4.29844,-9.72038 -9.63781,-9.81656l-0.18037,-0.00162l-0.18037,0.00162zm0.37882,-2.18183c6.53604,0.10596 11.80155,5.43708 11.80155,11.99839c0,6.62758 -5.37242,12 -12,12c-6.62758,0 -12,-5.37242 -12,-12c0,-6.56131 5.26551,-11.89243 11.80155,-11.99839l0.19845,-0.00161l0.19845,0.00161z"
              />
              <path
                id="形状结合"
                d="m12.02308,15.55385c0.84117,0 1.52307,0.6819 1.52307,1.52307c0,0.84118 -0.6819,1.52308 -1.52307,1.52308c-0.84118,0 -1.52308,-0.6819 -1.52308,-1.52308c0,-0.84117 0.6819,-1.52307 1.52308,-1.52307zm0.52307,-10.15385c0.55229,0 1,0.44772 1,1l0,7.23077c0,0.55228 -0.44771,1 -1,1l-1.04615,0c-0.55228,0 -1,-0.44772 -1,-1l0,-7.23077c0,-0.55228 0.44772,-1 1,-1l1.04615,0z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const warningIcon = (props) => <Icon component={warningSvg} {...props} />

export default warningIcon
