export default {
  'zh-CN': [
    {
      id: 79,
      name: '印度尼西亚',
      countryCallingCode: '62',
      isoCode: 'ID',
      mainCurrencyId: 167,
    },
    {
      id: 136,
      name: '菲律宾',
      countryCallingCode: '63',
      isoCode: 'PH',
      mainCurrencyId: 255,
    },
    {
      id: 105,
      name: '马来西亚',
      countryCallingCode: '60',
      isoCode: 'MY',
      mainCurrencyId: 273,
    },
    {
      id: 172,
      name: '泰国',
      countryCallingCode: '66',
      isoCode: 'TH',
      mainCurrencyId: 224,
    },
    {
      id: 13,
      name: '新加坡',
      countryCallingCode: '65',
      isoCode: 'SG',
      mainCurrencyId: 213,
    },
    {
      id: 169,
      name: '中國台湾',
      countryCallingCode: '886',
      isoCode: 'TW',
      mainCurrencyId: 172,
    },
    {
      id: 2,
      name: '中國香港',
      countryCallingCode: '852',
      isoCode: 'HK',
      mainCurrencyId: 228,
    },
    {
      id: 102,
      name: '中國澳门',
      countryCallingCode: '853',
      isoCode: 'MO',
      mainCurrencyId: 278,
    },
    {
      id: 1,
      name: '中国',
      countryCallingCode: '86',
      isoCode: 'CN',
      mainCurrencyId: 143,
    },
    {
      id: 3,
      name: '加拿大',
      countryCallingCode: '1',
      isoCode: 'CA',
      mainCurrencyId: 159,
    },
    {
      id: 4,
      name: '英国',
      countryCallingCode: '44',
      isoCode: 'GB',
      mainCurrencyId: 277,
    },
    {
      id: 5,
      name: '日本',
      countryCallingCode: '81',
      isoCode: 'JP',
      mainCurrencyId: 214,
    },
    {
      id: 6,
      name: '美国',
      countryCallingCode: '1',
      isoCode: 'US',
      mainCurrencyId: 247,
    },
    {
      id: 7,
      name: '韩国',
      countryCallingCode: '82',
      isoCode: 'KR',
      mainCurrencyId: 269,
    },
    {
      id: 8,
      name: '意大利',
      countryCallingCode: '39',
      isoCode: 'IT',
      mainCurrencyId: 276,
    },
    {
      id: 9,
      name: '阿富汗',
      countryCallingCode: '93',
      isoCode: 'AF',
      mainCurrencyId: 262,
    },
    {
      id: 10,
      name: '阿尔巴尼亚',
      countryCallingCode: '355',
      isoCode: 'AL',
      mainCurrencyId: 264,
    },
    {
      id: 11,
      name: '阿尔及利亚',
      countryCallingCode: '213',
      isoCode: 'DZ',
      mainCurrencyId: 263,
    },
    {
      id: 12,
      name: '印度',
      countryCallingCode: '91',
      isoCode: 'IN',
      mainCurrencyId: 166,
    },

    {
      id: 14,
      name: '德国',
      countryCallingCode: '49',
      isoCode: 'DE',
      mainCurrencyId: 276,
    },
    {
      id: 15,
      name: '阿根廷',
      countryCallingCode: '54',
      isoCode: 'AR',
      mainCurrencyId: 266,
    },
    {
      id: 16,
      name: '法国',
      countryCallingCode: '33',
      isoCode: 'FR',
      mainCurrencyId: 276,
    },
    {
      id: 17,
      name: '阿森松',
      countryCallingCode: '247',
      isoCode: 'AC',
      mainCurrencyId: 285,
    },
    {
      id: 18,
      name: '澳大利亚',
      countryCallingCode: '61',
      isoCode: 'AU',
      mainCurrencyId: 278,
    },
    {
      id: 19,
      name: '奥地利',
      countryCallingCode: '43',
      isoCode: 'AT',
      mainCurrencyId: 276,
    },
    {
      id: 20,
      name: '阿塞拜疆',
      countryCallingCode: '994',
      isoCode: 'AZ',
      mainCurrencyId: 261,
    },
    {
      id: 21,
      name: '巴哈马',
      countryCallingCode: '1242',
      isoCode: 'BS',
      mainCurrencyId: 198,
    },
    {
      id: 22,
      name: '巴林',
      countryCallingCode: '973',
      isoCode: 'BH',
      mainCurrencyId: 202,
    },
    {
      id: 23,
      name: '孟加拉国',
      countryCallingCode: '880',
      isoCode: 'BD',
      mainCurrencyId: 193,
    },
    {
      id: 24,
      name: '巴巴多斯',
      countryCallingCode: '1246',
      isoCode: 'BB',
      mainCurrencyId: 134,
    },
    {
      id: 25,
      name: '白俄罗斯',
      countryCallingCode: '375',
      isoCode: 'BY',
      mainCurrencyId: 236,
    },
    {
      id: 26,
      name: '比利时',
      countryCallingCode: '32',
      isoCode: 'BE',
      mainCurrencyId: 276,
    },
    {
      id: 27,
      name: '伯利兹',
      countryCallingCode: '501',
      isoCode: 'BZ',
      mainCurrencyId: 276,
    },
    {
      id: 28,
      name: '贝宁',
      countryCallingCode: '229',
      isoCode: 'BJ',
      mainCurrencyId: 258,
    },
    {
      id: 29,
      name: '百慕大群岛',
      countryCallingCode: '1441',
      isoCode: 'BM',
      mainCurrencyId: 237,
    },
    {
      id: 30,
      name: '玻利维亚',
      countryCallingCode: '591',
      isoCode: 'BO',
      mainCurrencyId: 232,
    },
    {
      id: 31,
      name: '博茨瓦纳',
      countryCallingCode: '267',
      isoCode: 'BW',
      mainCurrencyId: 280,
    },
    {
      id: 32,
      name: '巴西',
      countryCallingCode: '55',
      isoCode: 'BR',
      mainCurrencyId: 203,
    },
    {
      id: 33,
      name: '文莱',
      countryCallingCode: '673',
      isoCode: 'BN',
      mainCurrencyId: 210,
    },
    {
      id: 34,
      name: '保加利亚文莱',
      countryCallingCode: '359',
      isoCode: 'BG',
      mainCurrencyId: 151,
    },
    {
      id: 35,
      name: '布基纳法索',
      countryCallingCode: '226',
      isoCode: 'BF',
      mainCurrencyId: 258,
    },
    {
      id: 36,
      name: '缅甸',
      countryCallingCode: '95',
      isoCode: 'MM',
      mainCurrencyId: 245,
    },
    {
      id: 37,
      name: '布隆迪',
      countryCallingCode: '257',
      isoCode: 'BI',
      mainCurrencyId: 205,
    },
    {
      id: 38,
      name: '喀麦隆',
      countryCallingCode: '237',
      isoCode: 'CM',
      mainCurrencyId: 135,
    },
    {
      id: 39,
      name: '开曼群岛',
      countryCallingCode: '1345',
      isoCode: 'CYM',
      mainCurrencyId: 206,
    },
    {
      id: 40,
      name: '中非共和国',
      countryCallingCode: '236',
      isoCode: 'CF',
      mainCurrencyId: 135,
    },
    {
      id: 41,
      name: '乍得',
      countryCallingCode: '235',
      isoCode: 'TD',
      mainCurrencyId: 135,
    },
    {
      id: 42,
      name: '智利',
      countryCallingCode: '56',
      isoCode: 'CL',
      mainCurrencyId: 215,
    },
    {
      id: 43,
      name: '哥伦比亚',
      countryCallingCode: '57',
      isoCode: 'CO',
      mainCurrencyId: 176,
    },
    {
      id: 44,
      name: '刚果',
      countryCallingCode: '242',
      isoCode: 'CG',
      mainCurrencyId: 156,
    },
    {
      id: 45,
      name: '库克群岛',
      countryCallingCode: '682',
      isoCode: 'CK',
      mainCurrencyId: 279,
    },
    {
      id: 46,
      name: '哥斯达黎加',
      countryCallingCode: '506',
      isoCode: 'CR',
      mainCurrencyId: 177,
    },
    {
      id: 47,
      name: '古巴',
      countryCallingCode: '53',
      isoCode: 'CU',
      mainCurrencyId: 171,
    },
    {
      id: 48,
      name: '塞浦路斯',
      countryCallingCode: '357',
      isoCode: 'CY',
      mainCurrencyId: 276,
    },
    {
      id: 49,
      name: '捷克',
      countryCallingCode: '420',
      isoCode: 'CZ',
      mainCurrencyId: 290,
    },
    {
      id: 50,
      name: '丹麦',
      countryCallingCode: '45',
      isoCode: 'DK',
      mainCurrencyId: 136,
    },
    {
      id: 51,
      name: '吉布提',
      countryCallingCode: '253',
      isoCode: 'DJ',
      mainCurrencyId: 174,
    },
    {
      id: 52,
      name: '多米尼加共和国',
      countryCallingCode: '1',
      isoCode: 'DO',
      mainCurrencyId: 190,
    },
    {
      id: 53,
      name: '厄瓜多尔',
      countryCallingCode: '593',
      isoCode: 'EC',
      mainCurrencyId: 247,
    },
    {
      id: 54,
      name: '埃及',
      countryCallingCode: '20',
      isoCode: 'EG',
      mainCurrencyId: 183,
    },
    {
      id: 55,
      name: '萨尔瓦多',
      countryCallingCode: '503',
      isoCode: 'SV',
      mainCurrencyId: 256,
    },
    {
      id: 56,
      name: '爱沙尼亚',
      countryCallingCode: '372',
      isoCode: 'EE',
      mainCurrencyId: 229,
    },
    {
      id: 57,
      name: '埃塞俄比亚',
      countryCallingCode: '251',
      isoCode: 'ET',
      mainCurrencyId: 184,
    },
    {
      id: 58,
      name: '斐济',
      countryCallingCode: '679',
      isoCode: 'FJ',
      mainCurrencyId: 282,
    },
    {
      id: 59,
      name: '芬兰',
      countryCallingCode: '358',
      isoCode: 'FI',
      mainCurrencyId: 276,
    },
    {
      id: 60,
      name: '亚美尼亚',
      countryCallingCode: '374',
      isoCode: 'AM',
      mainCurrencyId: 142,
    },
    {
      id: 61,
      name: '法属圭亚那',
      countryCallingCode: '594',
      isoCode: 'GF',
      mainCurrencyId: 181,
    },
    {
      id: 62,
      name: '加蓬',
      countryCallingCode: '241',
      isoCode: 'GA',
      mainCurrencyId: 135,
    },
    {
      id: 63,
      name: '冈比亚',
      countryCallingCode: '220',
      isoCode: 'GM',
      mainCurrencyId: 153,
    },
    {
      id: 64,
      name: '格鲁吉亚',
      countryCallingCode: '995',
      isoCode: 'GE',
      mainCurrencyId: 218,
    },
    {
      id: 65,
      name: '安提瓜和巴布达',
      countryCallingCode: '1268',
      isoCode: 'AG',
      mainCurrencyId: 134,
    },
    {
      id: 66,
      name: '加纳',
      countryCallingCode: '233',
      isoCode: 'GH',
      mainCurrencyId: 160,
    },
    {
      id: 67,
      name: '直布罗陀',
      countryCallingCode: '350',
      isoCode: 'GI',
      mainCurrencyId: 283,
    },
    {
      id: 68,
      name: '希腊',
      countryCallingCode: '30',
      isoCode: 'GR',
      mainCurrencyId: 276,
    },
    {
      id: 69,
      name: '格林纳达',
      countryCallingCode: '1809',
      isoCode: 'GD',
      mainCurrencyId: 134,
    },
    {
      id: 70,
      name: '关岛',
      countryCallingCode: '1671',
      isoCode: 'GU',
      mainCurrencyId: 247,
    },
    {
      id: 71,
      name: '危地马拉肯尼亚',
      countryCallingCode: '502',
      isoCode: 'GT',
      mainCurrencyId: 168,
    },
    {
      id: 72,
      name: '几内亚',
      countryCallingCode: '224',
      isoCode: 'GN',
      mainCurrencyId: 155,
    },
    {
      id: 73,
      name: '圭亚那',
      countryCallingCode: '592',
      isoCode: 'GY',
      mainCurrencyId: 181,
    },
    {
      id: 74,
      name: '海地',
      countryCallingCode: '509',
      isoCode: 'HT',
      mainCurrencyId: 227,
    },
    {
      id: 75,
      name: '洪都拉斯',
      countryCallingCode: '504',
      isoCode: 'HN',
      mainCurrencyId: 226,
    },
    {
      id: 76,
      name: '匈牙利',
      countryCallingCode: '36',
      isoCode: 'HU',
      mainCurrencyId: 161,
    },
    {
      id: 77,
      name: '冰岛',
      countryCallingCode: '354',
      isoCode: 'IS',
      mainCurrencyId: 154,
    },
    {
      id: 78,
      name: '安道尔共和国',
      countryCallingCode: '376',
      isoCode: 'AD',
      mainCurrencyId: 276,
    },
    {
      id: 80,
      name: '伊朗',
      countryCallingCode: '98',
      isoCode: 'IR',
      mainCurrencyId: 147,
    },
    {
      id: 81,
      name: '伊拉克',
      countryCallingCode: '964',
      isoCode: 'IQ',
      mainCurrencyId: 146,
    },
    {
      id: 82,
      name: '爱尔兰',
      countryCallingCode: '353',
      isoCode: 'IE',
      mainCurrencyId: 276,
    },
    {
      id: 83,
      name: '以色列',
      countryCallingCode: '972',
      isoCode: 'IL',
      mainCurrencyId: 145,
    },
    {
      id: 84,
      name: '安哥拉',
      countryCallingCode: '244',
      isoCode: 'AO',
      mainCurrencyId: 194,
    },
    {
      id: 85,
      name: '科特迪瓦',
      countryCallingCode: '225',
      isoCode: 'CIV',
      mainCurrencyId: 258,
    },
    {
      id: 86,
      name: '牙买加',
      countryCallingCode: '1876',
      isoCode: 'JM',
      mainCurrencyId: 230,
    },
    {
      id: 87,
      name: '约旦',
      countryCallingCode: '962',
      isoCode: 'JO',
      mainCurrencyId: 243,
    },
    {
      id: 88,
      name: '柬埔寨',
      countryCallingCode: '855',
      isoCode: 'KH',
      mainCurrencyId: 217,
    },
    {
      id: 89,
      name: '哈萨克斯坦',
      countryCallingCode: '7',
      isoCode: 'KZ',
      mainCurrencyId: 175,
    },
    {
      id: 90,
      name: '肯尼亚',
      countryCallingCode: '254',
      isoCode: 'KE',
      mainCurrencyId: 249,
    },
    {
      id: 91,
      name: '科威特',
      countryCallingCode: '965',
      isoCode: 'KW',
      mainCurrencyId: 238,
    },
    {
      id: 92,
      name: '吉尔吉斯坦',
      countryCallingCode: '996',
      isoCode: 'KG',
      mainCurrencyId: 173,
    },
    {
      id: 93,
      name: '老挝',
      countryCallingCode: '856',
      isoCode: 'LA',
      mainCurrencyId: 248,
    },
    {
      id: 94,
      name: '拉脱维亚',
      countryCallingCode: '371',
      isoCode: 'LV',
      mainCurrencyId: 276,
    },
    {
      id: 95,
      name: '黎巴嫩',
      countryCallingCode: '961',
      isoCode: 'LB',
      mainCurrencyId: 275,
    },
    {
      id: 96,
      name: '莱索托',
      countryCallingCode: '266',
      isoCode: 'LS',
      mainCurrencyId: 254,
    },
    {
      id: 97,
      name: '利比里亚',
      countryCallingCode: '231',
      isoCode: 'LR',
      mainCurrencyId: 158,
    },
    {
      id: 98,
      name: '利比亚',
      countryCallingCode: '218',
      isoCode: 'LY',
      mainCurrencyId: 157,
    },
    {
      id: 99,
      name: '列支敦士登',
      countryCallingCode: '423',
      isoCode: 'LI',
      mainCurrencyId: 234,
    },
    {
      id: 100,
      name: '立陶宛',
      countryCallingCode: '370',
      isoCode: 'LT',
      mainCurrencyId: 289,
    },
    {
      id: 101,
      name: '卢森堡',
      countryCallingCode: '352',
      isoCode: 'LU',
      mainCurrencyId: 276,
    },
    {
      id: 103,
      name: '马达加斯加',
      countryCallingCode: '261',
      isoCode: 'MG',
      mainCurrencyId: 274,
    },
    {
      id: 104,
      name: '马拉维',
      countryCallingCode: '265',
      isoCode: 'MW',
      mainCurrencyId: 272,
    },
    {
      id: 106,
      name: '马尔代夫',
      countryCallingCode: '960',
      isoCode: 'MV',
      mainCurrencyId: 271,
    },
    {
      id: 107,
      name: '马里',
      countryCallingCode: '223',
      isoCode: 'ML',
      mainCurrencyId: 258,
    },
    {
      id: 108,
      name: '马耳他',
      countryCallingCode: '356',
      isoCode: 'MT',
      mainCurrencyId: 276,
    },
    {
      id: 109,
      name: '北马里亚纳',
      countryCallingCode: '1670',
      isoCode: 'MP',
      mainCurrencyId: 247,
    },
    {
      id: 110,
      name: '马提尼克',
      countryCallingCode: '596',
      isoCode: 'MQ',
      mainCurrencyId: 276,
    },
    {
      id: 111,
      name: '毛里求斯',
      countryCallingCode: '230',
      isoCode: 'MU',
      mainCurrencyId: 220,
    },
    {
      id: 112,
      name: '墨西哥',
      countryCallingCode: '52',
      isoCode: 'MX',
      mainCurrencyId: 189,
    },
    {
      id: 113,
      name: '摩尔多瓦',
      countryCallingCode: '373',
      isoCode: 'MD',
      mainCurrencyId: 208,
    },
    {
      id: 115,
      name: '蒙古',
      countryCallingCode: '976',
      isoCode: 'MN',
      mainCurrencyId: 257,
    },
    {
      id: 116,
      name: '蒙特塞拉特岛',
      countryCallingCode: '1664',
      isoCode: 'MS',
      mainCurrencyId: 134,
    },
    {
      id: 117,
      name: '摩洛哥',
      countryCallingCode: '212',
      isoCode: 'MA',
      mainCurrencyId: 209,
    },
    {
      id: 118,
      name: '莫桑比克',
      countryCallingCode: '258',
      isoCode: 'MZ',
      mainCurrencyId: 253,
    },
    {
      id: 119,
      name: '纳米比亚',
      countryCallingCode: '264',
      isoCode: 'NA',
      mainCurrencyId: 244,
    },
    {
      id: 120,
      name: '瑙鲁',
      countryCallingCode: '674',
      isoCode: 'NR',
      mainCurrencyId: 278,
    },
    {
      id: 121,
      name: '尼泊尔',
      countryCallingCode: '977',
      isoCode: 'NP',
      mainCurrencyId: 197,
    },
    {
      id: 122,
      name: '荷属安的列斯',
      countryCallingCode: '599',
      isoCode: 'AN',
      mainCurrencyId: 252,
    },
    {
      id: 123,
      name: '荷兰',
      countryCallingCode: '31',
      isoCode: 'NL',
      mainCurrencyId: 252,
    },
    {
      id: 124,
      name: '新西兰',
      countryCallingCode: '64',
      isoCode: 'NZ',
      mainCurrencyId: 279,
    },
    {
      id: 125,
      name: '尼加拉瓜',
      countryCallingCode: '505',
      isoCode: 'NI',
      mainCurrencyId: 195,
    },
    {
      id: 126,
      name: '尼日尔',
      countryCallingCode: '227',
      isoCode: 'NE',
      mainCurrencyId: 258,
    },
    {
      id: 127,
      name: '尼日利亚',
      countryCallingCode: '234',
      isoCode: 'NG',
      mainCurrencyId: 196,
    },
    {
      id: 128,
      name: '朝鲜',
      countryCallingCode: '850',
      isoCode: 'KP',
      mainCurrencyId: 216,
    },
    {
      id: 129,
      name: '挪威',
      countryCallingCode: '47',
      isoCode: 'NO',
      mainCurrencyId: 207,
    },
    {
      id: 130,
      name: '阿曼',
      countryCallingCode: '968',
      isoCode: 'OM',
      mainCurrencyId: 265,
    },
    {
      id: 131,
      name: '巴基斯坦',
      countryCallingCode: '92',
      isoCode: 'PK',
      mainCurrencyId: 199,
    },
    {
      id: 132,
      name: '巴拿马',
      countryCallingCode: '507',
      isoCode: 'PA',
      mainCurrencyId: 201,
    },
    {
      id: 133,
      name: '巴布亚新几内亚',
      countryCallingCode: '675',
      isoCode: 'PG',
      mainCurrencyId: 284,
    },
    {
      id: 134,
      name: '巴拉圭',
      countryCallingCode: '595',
      isoCode: 'PY',
      mainCurrencyId: 200,
    },
    {
      id: 135,
      name: '秘鲁',
      countryCallingCode: '51',
      isoCode: 'PE',
      mainCurrencyId: 240,
    },
    {
      id: 137,
      name: '波兰',
      countryCallingCode: '48',
      isoCode: 'PL',
      mainCurrencyId: 222,
    },
    {
      id: 138,
      name: '法属玻利尼西亚',
      countryCallingCode: '689',
      isoCode: 'PF',
      mainCurrencyId: 191,
    },
    {
      id: 139,
      name: '葡萄牙',
      countryCallingCode: '351',
      isoCode: 'PT',
      mainCurrencyId: 276,
    },
    {
      id: 140,
      name: '波多黎各',
      countryCallingCode: '1787',
      isoCode: 'PR',
      mainCurrencyId: 247,
    },
    {
      id: 141,
      name: '卡塔尔',
      countryCallingCode: '974',
      isoCode: 'QA',
      mainCurrencyId: 164,
    },
    {
      id: 142,
      name: '留尼旺',
      countryCallingCode: '262',
      isoCode: 'RE',
      mainCurrencyId: 276,
    },
    {
      id: 143,
      name: '罗马尼亚',
      countryCallingCode: '40',
      isoCode: 'RO',
      mainCurrencyId: 246,
    },
    {
      id: 144,
      name: '俄罗斯',
      countryCallingCode: '7',
      isoCode: 'RU',
      mainCurrencyId: 150,
    },
    {
      id: 145,
      name: '圣卢西亚',
      countryCallingCode: '1758',
      isoCode: 'LC',
      mainCurrencyId: 134,
    },
    {
      id: 146,
      name: '美属萨摩亚',
      countryCallingCode: '684',
      isoCode: 'AS',
      mainCurrencyId: 247,
    },
    {
      id: 147,
      name: '西萨摩亚',
      countryCallingCode: '685',
      isoCode: 'WS',
      mainCurrencyId: 287,
    },
    {
      id: 148,
      name: '圣马力诺',
      countryCallingCode: '378',
      isoCode: 'SM',
      mainCurrencyId: 276,
    },
    {
      id: 149,
      name: '圣多美和普林西比',
      countryCallingCode: '239',
      isoCode: 'ST',
      mainCurrencyId: 180,
    },
    {
      id: 150,
      name: '沙特阿拉伯',
      countryCallingCode: '966',
      isoCode: 'SA',
      mainCurrencyId: 221,
    },
    {
      id: 151,
      name: '塞内加尔',
      countryCallingCode: '221',
      isoCode: 'SN',
      mainCurrencyId: 258,
    },
    {
      id: 152,
      name: '塞舌尔',
      countryCallingCode: '248',
      isoCode: 'SC',
      mainCurrencyId: 188,
    },
    {
      id: 153,
      name: '塞拉利昂',
      countryCallingCode: '232',
      isoCode: 'SL',
      mainCurrencyId: 187,
    },
    {
      id: 154,
      name: '安圭拉岛',
      countryCallingCode: '1264',
      isoCode: 'AI',
      mainCurrencyId: 134,
    },
    {
      id: 155,
      name: '斯洛伐克',
      countryCallingCode: '421',
      isoCode: 'SK',
      mainCurrencyId: 276,
    },
    {
      id: 156,
      name: '斯洛文尼亚',
      countryCallingCode: '386',
      isoCode: 'SI',
      mainCurrencyId: 276,
    },
    {
      id: 157,
      name: '所罗门群岛',
      countryCallingCode: '677',
      isoCode: 'SB',
      mainCurrencyId: 286,
    },
    {
      id: 158,
      name: '索马里',
      countryCallingCode: '252',
      isoCode: 'SO',
      mainCurrencyId: 242,
    },
    {
      id: 159,
      name: '南非',
      countryCallingCode: '27',
      isoCode: 'ZA',
      mainCurrencyId: 163,
    },
    {
      id: 160,
      name: '西班牙',
      countryCallingCode: '34',
      isoCode: 'ES',
      mainCurrencyId: 276,
    },
    {
      id: 161,
      name: '斯里兰卡',
      countryCallingCode: '94',
      isoCode: 'LK',
      mainCurrencyId: 212,
    },
    {
      id: 162,
      name: '圣文森特',
      countryCallingCode: '1784',
      isoCode: 'VC',
      mainCurrencyId: 134,
    },
    {
      id: 163,
      name: '苏丹',
      countryCallingCode: '249',
      isoCode: 'SD',
      mainCurrencyId: 250,
    },
    {
      id: 164,
      name: '苏里南',
      countryCallingCode: '597',
      isoCode: 'SR',
      mainCurrencyId: 251,
    },
    {
      id: 165,
      name: '斯威士兰',
      countryCallingCode: '268',
      isoCode: 'SZ',
      mainCurrencyId: 211,
    },
    {
      id: 166,
      name: '瑞典',
      countryCallingCode: '46',
      isoCode: 'SE',
      mainCurrencyId: 233,
    },
    {
      id: 167,
      name: '瑞士',
      countryCallingCode: '41',
      isoCode: 'CH',
      mainCurrencyId: 234,
    },
    {
      id: 168,
      name: '叙利亚',
      countryCallingCode: '963',
      isoCode: 'SY',
      mainCurrencyId: 170,
    },
    {
      id: 170,
      name: '塔吉克斯坦',
      countryCallingCode: '992',
      isoCode: 'TJ',
      mainCurrencyId: 185,
    },
    {
      id: 171,
      name: '坦桑尼亚',
      countryCallingCode: '255',
      isoCode: 'TZ',
      mainCurrencyId: 182,
    },

    {
      id: 173,
      name: '多哥',
      countryCallingCode: '228',
      isoCode: 'TG',
      mainCurrencyId: 258,
    },
    {
      id: 174,
      name: '汤加',
      countryCallingCode: '676',
      isoCode: 'TO',
      mainCurrencyId: 288,
    },
    {
      id: 175,
      name: '特立尼达和多巴哥',
      countryCallingCode: '1868',
      isoCode: 'TT',
      mainCurrencyId: 231,
    },
    {
      id: 176,
      name: '突尼斯',
      countryCallingCode: '216',
      isoCode: 'TN',
      mainCurrencyId: 241,
    },
    {
      id: 177,
      name: '土耳其',
      countryCallingCode: '90',
      isoCode: 'TR',
      mainCurrencyId: 179,
    },
    {
      id: 178,
      name: '土库曼斯坦',
      countryCallingCode: '993',
      isoCode: 'TM',
      mainCurrencyId: 178,
    },
    {
      id: 179,
      name: '乌干达',
      countryCallingCode: '256',
      isoCode: 'UG',
      mainCurrencyId: 139,
    },
    {
      id: 180,
      name: '乌克兰',
      countryCallingCode: '380',
      isoCode: 'UA',
      mainCurrencyId: 137,
    },
    {
      id: 181,
      name: '阿拉伯联合酋长国',
      countryCallingCode: '971',
      isoCode: 'AE',
      mainCurrencyId: 267,
    },
    {
      id: 182,
      name: '乌拉圭',
      countryCallingCode: '598',
      isoCode: 'UY',
      mainCurrencyId: 140,
    },
    {
      id: 183,
      name: '乌兹别克斯坦',
      countryCallingCode: '998',
      isoCode: 'UZ',
      mainCurrencyId: 138,
    },
    {
      id: 184,
      name: '委内瑞拉',
      countryCallingCode: '58',
      isoCode: 'VE',
      mainCurrencyId: 192,
    },
    {
      id: 185,
      name: '越南',
      countryCallingCode: '84',
      isoCode: 'VN',
      mainCurrencyId: 260,
    },
    {
      id: 186,
      name: '也门',
      countryCallingCode: '967',
      isoCode: 'YE',
      mainCurrencyId: 141,
    },
    {
      id: 188,
      name: '津巴布韦',
      countryCallingCode: '263',
      isoCode: 'ZW',
      mainCurrencyId: 225,
    },
    {
      id: 189,
      name: '扎伊尔',
      countryCallingCode: '243',
      isoCode: 'ZR',
      mainCurrencyId: 156,
    },
    {
      id: 190,
      name: '赞比亚',
      countryCallingCode: '260',
      isoCode: 'ZM',
      mainCurrencyId: 259,
    },
    {
      id: 192,
      name: '塞尔维亚',
      countryCallingCode: '381',
      isoCode: 'RS',
      mainCurrencyId: 186,
    },
    {
      id: 193,
      name: '波多黎各2',
      countryCallingCode: '1939',
      isoCode: 'PR',
      mainCurrencyId: 247,
    },
  ],
  'en-US': [
    {
      id: 79,
      name: 'Indonesia',
      countryCallingCode: '62',
      isoCode: 'ID',
      mainCurrencyId: 167,
    },
    {
      id: 136,
      name: 'Philippines',
      countryCallingCode: '63',
      isoCode: 'PH',
      mainCurrencyId: 255,
    },
    {
      id: 105,
      name: 'Malaysia',
      countryCallingCode: '60',
      isoCode: 'MY',
      mainCurrencyId: 273,
    },
    {
      id: 172,
      name: 'Thailand',
      countryCallingCode: '66',
      isoCode: 'TH',
      mainCurrencyId: 224,
    },
    {
      id: 13,
      name: 'Singapore',
      countryCallingCode: '65',
      isoCode: 'SG',
      mainCurrencyId: 213,
    },
    {
      id: 169,
      name: 'Taiwan, China',
      countryCallingCode: '886',
      isoCode: 'TW',
      mainCurrencyId: 172,
    },
    {
      id: 2,
      name: 'Hong Kong, China',
      countryCallingCode: '852',
      isoCode: 'HK',
      mainCurrencyId: 228,
    },
    {
      id: 102,
      name: 'Macao, China',
      countryCallingCode: '853',
      isoCode: 'MO',
      mainCurrencyId: 278,
    },
    {
      id: 1,
      name: 'China',
      countryCallingCode: '86',
      isoCode: 'CN',
      mainCurrencyId: 143,
    },

    {
      id: 3,
      name: 'Canada',
      countryCallingCode: '1',
      isoCode: 'CA',
      mainCurrencyId: 159,
    },
    {
      id: 4,
      name: 'United Kingdom',
      countryCallingCode: '44',
      isoCode: 'GB',
      mainCurrencyId: 277,
    },
    {
      id: 5,
      name: 'Japan',
      countryCallingCode: '81',
      isoCode: 'JP',
      mainCurrencyId: 214,
    },
    {
      id: 6,
      name: 'United States',
      countryCallingCode: '1',
      isoCode: 'US',
      mainCurrencyId: 247,
    },
    {
      id: 7,
      name: 'Korea',
      countryCallingCode: '82',
      isoCode: 'KR',
      mainCurrencyId: 269,
    },
    {
      id: 8,
      name: 'Italy',
      countryCallingCode: '39',
      isoCode: 'IT',
      mainCurrencyId: 276,
    },
    {
      id: 9,
      name: 'Afghanistan',
      countryCallingCode: '93',
      isoCode: 'AF',
      mainCurrencyId: 262,
    },
    {
      id: 10,
      name: 'Albania',
      countryCallingCode: '355',
      isoCode: 'AL',
      mainCurrencyId: 264,
    },
    {
      id: 11,
      name: 'Algeria',
      countryCallingCode: '213',
      isoCode: 'DZ',
      mainCurrencyId: 263,
    },
    {
      id: 12,
      name: 'India',
      countryCallingCode: '91',
      isoCode: 'IN',
      mainCurrencyId: 166,
    },
    {
      id: 14,
      name: 'Germany',
      countryCallingCode: '49',
      isoCode: 'DE',
      mainCurrencyId: 276,
    },
    {
      id: 15,
      name: 'Argentina',
      countryCallingCode: '54',
      isoCode: 'AR',
      mainCurrencyId: 266,
    },
    {
      id: 16,
      name: 'France',
      countryCallingCode: '33',
      isoCode: 'FR',
      mainCurrencyId: 276,
    },
    {
      id: 17,
      name: 'Ascension',
      countryCallingCode: '247',
      isoCode: 'AC',
      mainCurrencyId: 285,
    },
    {
      id: 18,
      name: 'Australia',
      countryCallingCode: '61',
      isoCode: 'AU',
      mainCurrencyId: 278,
    },
    {
      id: 19,
      name: 'Austria',
      countryCallingCode: '43',
      isoCode: 'AT',
      mainCurrencyId: 276,
    },
    {
      id: 20,
      name: 'Azerbaijan',
      countryCallingCode: '994',
      isoCode: 'AZ',
      mainCurrencyId: 261,
    },
    {
      id: 21,
      name: 'Bahamas',
      countryCallingCode: '1242',
      isoCode: 'BS',
      mainCurrencyId: 198,
    },
    {
      id: 22,
      name: 'Bahrain',
      countryCallingCode: '973',
      isoCode: 'BH',
      mainCurrencyId: 202,
    },
    {
      id: 23,
      name: 'Bangladesh',
      countryCallingCode: '880',
      isoCode: 'BD',
      mainCurrencyId: 193,
    },
    {
      id: 24,
      name: 'Barbados',
      countryCallingCode: '1246',
      isoCode: 'BB',
      mainCurrencyId: 134,
    },
    {
      id: 25,
      name: 'Belarus',
      countryCallingCode: '375',
      isoCode: 'BY',
      mainCurrencyId: 236,
    },
    {
      id: 26,
      name: 'Belgium',
      countryCallingCode: '32',
      isoCode: 'BE',
      mainCurrencyId: 276,
    },
    {
      id: 27,
      name: 'Belize',
      countryCallingCode: '501',
      isoCode: 'BZ',
      mainCurrencyId: 276,
    },
    {
      id: 28,
      name: 'Benin',
      countryCallingCode: '229',
      isoCode: 'BJ',
      mainCurrencyId: 258,
    },
    {
      id: 29,
      name: 'Bermuda Is.',
      countryCallingCode: '1441',
      isoCode: 'BM',
      mainCurrencyId: 237,
    },
    {
      id: 30,
      name: 'Bolivia',
      countryCallingCode: '591',
      isoCode: 'BO',
      mainCurrencyId: 232,
    },
    {
      id: 31,
      name: 'Botswana',
      countryCallingCode: '267',
      isoCode: 'BW',
      mainCurrencyId: 280,
    },
    {
      id: 32,
      name: 'Brazil',
      countryCallingCode: '55',
      isoCode: 'BR',
      mainCurrencyId: 203,
    },
    {
      id: 33,
      name: 'Brunei',
      countryCallingCode: '673',
      isoCode: 'BN',
      mainCurrencyId: 210,
    },
    {
      id: 34,
      name: 'Bulgaria',
      countryCallingCode: '359',
      isoCode: 'BG',
      mainCurrencyId: 151,
    },
    {
      id: 35,
      name: 'Burkina',
      countryCallingCode: '226',
      isoCode: 'BF',
      mainCurrencyId: 258,
    },
    {
      id: 36,
      name: 'Burma',
      countryCallingCode: '95',
      isoCode: 'MM',
      mainCurrencyId: 245,
    },
    {
      id: 37,
      name: 'Burundi',
      countryCallingCode: '257',
      isoCode: 'BI',
      mainCurrencyId: 205,
    },
    {
      id: 38,
      name: 'Cameroon',
      countryCallingCode: '237',
      isoCode: 'CM',
      mainCurrencyId: 135,
    },
    {
      id: 39,
      name: 'Cayman Is.',
      countryCallingCode: '1345',
      isoCode: 'CYM',
      mainCurrencyId: 206,
    },
    {
      id: 40,
      name: 'Central African Republic',
      countryCallingCode: '236',
      isoCode: 'CF',
      mainCurrencyId: 135,
    },
    {
      id: 41,
      name: 'Chad',
      countryCallingCode: '235',
      isoCode: 'TD',
      mainCurrencyId: 135,
    },
    {
      id: 42,
      name: 'Chile',
      countryCallingCode: '56',
      isoCode: 'CL',
      mainCurrencyId: 215,
    },
    {
      id: 43,
      name: 'Colombia',
      countryCallingCode: '57',
      isoCode: 'CO',
      mainCurrencyId: 176,
    },
    {
      id: 44,
      name: 'Congo',
      countryCallingCode: '242',
      isoCode: 'CG',
      mainCurrencyId: 156,
    },
    {
      id: 45,
      name: 'Cook Is.',
      countryCallingCode: '682',
      isoCode: 'CK',
      mainCurrencyId: 279,
    },
    {
      id: 46,
      name: 'Costa Rica',
      countryCallingCode: '506',
      isoCode: 'CR',
      mainCurrencyId: 177,
    },
    {
      id: 47,
      name: 'Cuba',
      countryCallingCode: '53',
      isoCode: 'CU',
      mainCurrencyId: 171,
    },
    {
      id: 48,
      name: 'Cyprus',
      countryCallingCode: '357',
      isoCode: 'CY',
      mainCurrencyId: 276,
    },
    {
      id: 49,
      name: 'Czech Republic',
      countryCallingCode: '420',
      isoCode: 'CZ',
      mainCurrencyId: 290,
    },
    {
      id: 50,
      name: 'Denmark',
      countryCallingCode: '45',
      isoCode: 'DK',
      mainCurrencyId: 136,
    },
    {
      id: 51,
      name: 'Djibouti',
      countryCallingCode: '253',
      isoCode: 'DJ',
      mainCurrencyId: 174,
    },
    {
      id: 52,
      name: 'Dominica Rep.',
      countryCallingCode: '1',
      isoCode: 'DO',
      mainCurrencyId: 190,
    },
    {
      id: 53,
      name: 'Ecuador',
      countryCallingCode: '593',
      isoCode: 'EC',
      mainCurrencyId: 247,
    },
    {
      id: 54,
      name: 'Egypt',
      countryCallingCode: '20',
      isoCode: 'EG',
      mainCurrencyId: 183,
    },
    {
      id: 55,
      name: 'EI Salvador',
      countryCallingCode: '503',
      isoCode: 'SV',
      mainCurrencyId: 256,
    },
    {
      id: 56,
      name: 'Estonia',
      countryCallingCode: '372',
      isoCode: 'EE',
      mainCurrencyId: 229,
    },
    {
      id: 57,
      name: 'Ethiopia',
      countryCallingCode: '251',
      isoCode: 'ET',
      mainCurrencyId: 184,
    },
    {
      id: 58,
      name: 'Fiji',
      countryCallingCode: '679',
      isoCode: 'FJ',
      mainCurrencyId: 282,
    },
    {
      id: 59,
      name: 'Finland',
      countryCallingCode: '358',
      isoCode: 'FI',
      mainCurrencyId: 276,
    },
    {
      id: 60,
      name: 'Armenia',
      countryCallingCode: '374',
      isoCode: 'AM',
      mainCurrencyId: 142,
    },
    {
      id: 61,
      name: 'French Guiana',
      countryCallingCode: '594',
      isoCode: 'GF',
      mainCurrencyId: 181,
    },
    {
      id: 62,
      name: 'Gabon',
      countryCallingCode: '241',
      isoCode: 'GA',
      mainCurrencyId: 135,
    },
    {
      id: 63,
      name: 'Gambia',
      countryCallingCode: '220',
      isoCode: 'GM',
      mainCurrencyId: 153,
    },
    {
      id: 64,
      name: 'Georgia',
      countryCallingCode: '995',
      isoCode: 'GE',
      mainCurrencyId: 218,
    },
    {
      id: 65,
      name: 'Antigua and Barbuda',
      countryCallingCode: '1268',
      isoCode: 'AG',
      mainCurrencyId: 134,
    },
    {
      id: 66,
      name: 'Ghana',
      countryCallingCode: '233',
      isoCode: 'GH',
      mainCurrencyId: 160,
    },
    {
      id: 67,
      name: 'Gibraltar',
      countryCallingCode: '350',
      isoCode: 'GI',
      mainCurrencyId: 283,
    },
    {
      id: 68,
      name: 'Greece',
      countryCallingCode: '30',
      isoCode: 'GR',
      mainCurrencyId: 276,
    },
    {
      id: 69,
      name: 'Grenada',
      countryCallingCode: '1809',
      isoCode: 'GD',
      mainCurrencyId: 134,
    },
    {
      id: 70,
      name: 'Guam',
      countryCallingCode: '1671',
      isoCode: 'GU',
      mainCurrencyId: 247,
    },
    {
      id: 71,
      name: 'Guatemala',
      countryCallingCode: '502',
      isoCode: 'GT',
      mainCurrencyId: 168,
    },
    {
      id: 72,
      name: 'Guinea',
      countryCallingCode: '224',
      isoCode: 'GN',
      mainCurrencyId: 155,
    },
    {
      id: 73,
      name: 'Guyana',
      countryCallingCode: '592',
      isoCode: 'GY',
      mainCurrencyId: 181,
    },
    {
      id: 74,
      name: 'Haiti',
      countryCallingCode: '509',
      isoCode: 'HT',
      mainCurrencyId: 227,
    },
    {
      id: 75,
      name: 'Honduras',
      countryCallingCode: '504',
      isoCode: 'HN',
      mainCurrencyId: 226,
    },
    {
      id: 76,
      name: 'Hungary',
      countryCallingCode: '36',
      isoCode: 'HU',
      mainCurrencyId: 161,
    },
    {
      id: 77,
      name: 'Iceland',
      countryCallingCode: '354',
      isoCode: 'IS',
      mainCurrencyId: 154,
    },
    {
      id: 78,
      name: 'Andorra',
      countryCallingCode: '376',
      isoCode: 'AD',
      mainCurrencyId: 276,
    },

    {
      id: 80,
      name: 'Iran',
      countryCallingCode: '98',
      isoCode: 'IR',
      mainCurrencyId: 147,
    },
    {
      id: 81,
      name: 'Iraq',
      countryCallingCode: '964',
      isoCode: 'IQ',
      mainCurrencyId: 146,
    },
    {
      id: 82,
      name: 'Ireland',
      countryCallingCode: '353',
      isoCode: 'IE',
      mainCurrencyId: 276,
    },
    {
      id: 83,
      name: 'Israel',
      countryCallingCode: '972',
      isoCode: 'IL',
      mainCurrencyId: 145,
    },
    {
      id: 84,
      name: 'Angola',
      countryCallingCode: '244',
      isoCode: 'AO',
      mainCurrencyId: 194,
    },
    {
      id: 85,
      name: 'Ivory Coast',
      countryCallingCode: '225',
      isoCode: 'CIV',
      mainCurrencyId: 258,
    },
    {
      id: 86,
      name: 'Jamaica',
      countryCallingCode: '1876',
      isoCode: 'JM',
      mainCurrencyId: 230,
    },
    {
      id: 87,
      name: 'Jordan',
      countryCallingCode: '962',
      isoCode: 'JO',
      mainCurrencyId: 243,
    },
    {
      id: 88,
      name: 'Cambodia',
      countryCallingCode: '855',
      isoCode: 'KH',
      mainCurrencyId: 217,
    },
    {
      id: 89,
      name: 'Kazakstan',
      countryCallingCode: '7',
      isoCode: 'KZ',
      mainCurrencyId: 175,
    },
    {
      id: 90,
      name: 'Kenya',
      countryCallingCode: '254',
      isoCode: 'KE',
      mainCurrencyId: 249,
    },
    {
      id: 91,
      name: 'Kuwait',
      countryCallingCode: '965',
      isoCode: 'KW',
      mainCurrencyId: 238,
    },
    {
      id: 92,
      name: 'Kyrgyzstan',
      countryCallingCode: '996',
      isoCode: 'KG',
      mainCurrencyId: 173,
    },
    {
      id: 93,
      name: 'Laos',
      countryCallingCode: '856',
      isoCode: 'LA',
      mainCurrencyId: 248,
    },
    {
      id: 94,
      name: 'Latvia',
      countryCallingCode: '371',
      isoCode: 'LV',
      mainCurrencyId: 276,
    },
    {
      id: 95,
      name: 'Lebanon',
      countryCallingCode: '961',
      isoCode: 'LB',
      mainCurrencyId: 275,
    },
    {
      id: 96,
      name: 'Lesotho',
      countryCallingCode: '266',
      isoCode: 'LS',
      mainCurrencyId: 254,
    },
    {
      id: 97,
      name: 'Liberia',
      countryCallingCode: '231',
      isoCode: 'LR',
      mainCurrencyId: 158,
    },
    {
      id: 98,
      name: 'Libya',
      countryCallingCode: '218',
      isoCode: 'LY',
      mainCurrencyId: 157,
    },
    {
      id: 99,
      name: 'Liechtenstein',
      countryCallingCode: '423',
      isoCode: 'LI',
      mainCurrencyId: 234,
    },
    {
      id: 100,
      name: 'Lithuania',
      countryCallingCode: '370',
      isoCode: 'LT',
      mainCurrencyId: 289,
    },
    {
      id: 101,
      name: 'Luxembourg',
      countryCallingCode: '352',
      isoCode: 'LU',
      mainCurrencyId: 276,
    },
    {
      id: 103,
      name: 'Madagascar',
      countryCallingCode: '261',
      isoCode: 'MG',
      mainCurrencyId: 274,
    },
    {
      id: 104,
      name: 'Malawi',
      countryCallingCode: '265',
      isoCode: 'MW',
      mainCurrencyId: 272,
    },
    {
      id: 106,
      name: 'Maldives',
      countryCallingCode: '960',
      isoCode: 'MV',
      mainCurrencyId: 271,
    },
    {
      id: 107,
      name: 'Mali',
      countryCallingCode: '223',
      isoCode: 'ML',
      mainCurrencyId: 258,
    },
    {
      id: 108,
      name: 'Malta',
      countryCallingCode: '356',
      isoCode: 'MT',
      mainCurrencyId: 276,
    },
    {
      id: 109,
      name: 'Northern Marianas',
      countryCallingCode: '1670',
      isoCode: 'MP',
      mainCurrencyId: 247,
    },
    {
      id: 110,
      name: 'Martinique',
      countryCallingCode: '596',
      isoCode: 'MQ',
      mainCurrencyId: 276,
    },
    {
      id: 111,
      name: 'Mauritius',
      countryCallingCode: '230',
      isoCode: 'MU',
      mainCurrencyId: 220,
    },
    {
      id: 112,
      name: 'Mexico',
      countryCallingCode: '52',
      isoCode: 'MX',
      mainCurrencyId: 189,
    },
    {
      id: 113,
      name: 'Moldova',
      countryCallingCode: '373',
      isoCode: 'MD',
      mainCurrencyId: 208,
    },
    {
      id: 115,
      name: 'Mongolia',
      countryCallingCode: '976',
      isoCode: 'MN',
      mainCurrencyId: 257,
    },
    {
      id: 116,
      name: 'Montserrat Is',
      countryCallingCode: '1664',
      isoCode: 'MS',
      mainCurrencyId: 134,
    },
    {
      id: 117,
      name: 'Morocco',
      countryCallingCode: '212',
      isoCode: 'MA',
      mainCurrencyId: 209,
    },
    {
      id: 118,
      name: 'Mozambique',
      countryCallingCode: '258',
      isoCode: 'MZ',
      mainCurrencyId: 253,
    },
    {
      id: 119,
      name: 'Namibia',
      countryCallingCode: '264',
      isoCode: 'NA',
      mainCurrencyId: 244,
    },
    {
      id: 120,
      name: 'Nauru',
      countryCallingCode: '674',
      isoCode: 'NR',
      mainCurrencyId: 278,
    },
    {
      id: 121,
      name: 'Nepal',
      countryCallingCode: '977',
      isoCode: 'NP',
      mainCurrencyId: 197,
    },
    {
      id: 122,
      name: 'Netherlands Antilles',
      countryCallingCode: '599',
      isoCode: 'AN',
      mainCurrencyId: 252,
    },
    {
      id: 123,
      name: 'Netherlands',
      countryCallingCode: '31',
      isoCode: 'NL',
      mainCurrencyId: 252,
    },
    {
      id: 124,
      name: 'New Zealand',
      countryCallingCode: '64',
      isoCode: 'NZ',
      mainCurrencyId: 279,
    },
    {
      id: 125,
      name: 'Nicaragua',
      countryCallingCode: '505',
      isoCode: 'NI',
      mainCurrencyId: 195,
    },
    {
      id: 126,
      name: 'Niger',
      countryCallingCode: '227',
      isoCode: 'NE',
      mainCurrencyId: 258,
    },
    {
      id: 127,
      name: 'Nigeria',
      countryCallingCode: '234',
      isoCode: 'NG',
      mainCurrencyId: 196,
    },
    {
      id: 128,
      name: 'North Korea',
      countryCallingCode: '850',
      isoCode: 'KP',
      mainCurrencyId: 216,
    },
    {
      id: 129,
      name: 'Norway',
      countryCallingCode: '47',
      isoCode: 'NO',
      mainCurrencyId: 207,
    },
    {
      id: 130,
      name: 'Oman',
      countryCallingCode: '968',
      isoCode: 'OM',
      mainCurrencyId: 265,
    },
    {
      id: 131,
      name: 'Pakistan',
      countryCallingCode: '92',
      isoCode: 'PK',
      mainCurrencyId: 199,
    },
    {
      id: 132,
      name: 'Panama',
      countryCallingCode: '507',
      isoCode: 'PA',
      mainCurrencyId: 201,
    },
    {
      id: 133,
      name: 'Papua New Cuinea',
      countryCallingCode: '675',
      isoCode: 'PG',
      mainCurrencyId: 284,
    },
    {
      id: 134,
      name: 'Paraguay',
      countryCallingCode: '595',
      isoCode: 'PY',
      mainCurrencyId: 200,
    },
    {
      id: 135,
      name: 'Peru',
      countryCallingCode: '51',
      isoCode: 'PE',
      mainCurrencyId: 240,
    },
    {
      id: 137,
      name: 'Poland',
      countryCallingCode: '48',
      isoCode: 'PL',
      mainCurrencyId: 222,
    },
    {
      id: 138,
      name: 'French Polynesia',
      countryCallingCode: '689',
      isoCode: 'PF',
      mainCurrencyId: 191,
    },
    {
      id: 139,
      name: 'Portugal',
      countryCallingCode: '351',
      isoCode: 'PT',
      mainCurrencyId: 276,
    },
    {
      id: 140,
      name: 'Puerto Rico',
      countryCallingCode: '1787',
      isoCode: 'PR',
      mainCurrencyId: 247,
    },
    {
      id: 141,
      name: 'Qatar',
      countryCallingCode: '974',
      isoCode: 'QA',
      mainCurrencyId: 164,
    },
    {
      id: 142,
      name: 'Reunion',
      countryCallingCode: '262',
      isoCode: 'RE',
      mainCurrencyId: 276,
    },
    {
      id: 143,
      name: 'Romania',
      countryCallingCode: '40',
      isoCode: 'RO',
      mainCurrencyId: 246,
    },
    {
      id: 144,
      name: 'Russia',
      countryCallingCode: '7',
      isoCode: 'RU',
      mainCurrencyId: 150,
    },
    {
      id: 145,
      name: 'Saint Lucia',
      countryCallingCode: '1758',
      isoCode: 'LC',
      mainCurrencyId: 134,
    },
    {
      id: 146,
      name: 'American Samoa',
      countryCallingCode: '684',
      isoCode: 'AS',
      mainCurrencyId: 247,
    },
    {
      id: 147,
      name: 'Western Samoa',
      countryCallingCode: '685',
      isoCode: 'WS',
      mainCurrencyId: 287,
    },
    {
      id: 148,
      name: 'San Marino',
      countryCallingCode: '378',
      isoCode: 'SM',
      mainCurrencyId: 276,
    },
    {
      id: 149,
      name: 'Sao Tome and Principe',
      countryCallingCode: '239',
      isoCode: 'ST',
      mainCurrencyId: 180,
    },
    {
      id: 150,
      name: 'Saudi Arabia',
      countryCallingCode: '966',
      isoCode: 'SA',
      mainCurrencyId: 221,
    },
    {
      id: 151,
      name: 'Senegal',
      countryCallingCode: '221',
      isoCode: 'SN',
      mainCurrencyId: 258,
    },
    {
      id: 152,
      name: 'Seychelles',
      countryCallingCode: '248',
      isoCode: 'SC',
      mainCurrencyId: 188,
    },
    {
      id: 153,
      name: 'Sierra Leone',
      countryCallingCode: '232',
      isoCode: 'SL',
      mainCurrencyId: 187,
    },
    {
      id: 154,
      name: 'Anguilla',
      countryCallingCode: '1264',
      isoCode: 'AI',
      mainCurrencyId: 134,
    },
    {
      id: 155,
      name: 'Slovakia',
      countryCallingCode: '421',
      isoCode: 'SK',
      mainCurrencyId: 276,
    },
    {
      id: 156,
      name: 'Slovenia',
      countryCallingCode: '386',
      isoCode: 'SI',
      mainCurrencyId: 276,
    },
    {
      id: 157,
      name: 'Solomon Islands',
      countryCallingCode: '677',
      isoCode: 'SB',
      mainCurrencyId: 286,
    },
    {
      id: 158,
      name: 'Somalia',
      countryCallingCode: '252',
      isoCode: 'SO',
      mainCurrencyId: 242,
    },
    {
      id: 159,
      name: 'South Africa',
      countryCallingCode: '27',
      isoCode: 'ZA',
      mainCurrencyId: 163,
    },
    {
      id: 160,
      name: 'Spain',
      countryCallingCode: '34',
      isoCode: 'ES',
      mainCurrencyId: 276,
    },
    {
      id: 161,
      name: 'Sri Lanka',
      countryCallingCode: '94',
      isoCode: 'LK',
      mainCurrencyId: 212,
    },
    {
      id: 162,
      name: 'St.Vincent',
      countryCallingCode: '1784',
      isoCode: 'VC',
      mainCurrencyId: 134,
    },
    {
      id: 163,
      name: 'Sudan',
      countryCallingCode: '249',
      isoCode: 'SD',
      mainCurrencyId: 250,
    },
    {
      id: 164,
      name: 'Suriname',
      countryCallingCode: '597',
      isoCode: 'SR',
      mainCurrencyId: 251,
    },
    {
      id: 165,
      name: 'Swaziland',
      countryCallingCode: '268',
      isoCode: 'SZ',
      mainCurrencyId: 211,
    },
    {
      id: 166,
      name: 'Sweden',
      countryCallingCode: '46',
      isoCode: 'SE',
      mainCurrencyId: 233,
    },
    {
      id: 167,
      name: 'Switzerland',
      countryCallingCode: '41',
      isoCode: 'CH',
      mainCurrencyId: 234,
    },
    {
      id: 168,
      name: 'Syria',
      countryCallingCode: '963',
      isoCode: 'SY',
      mainCurrencyId: 170,
    },
    {
      id: 170,
      name: 'Tajikistan',
      countryCallingCode: '992',
      isoCode: 'TJ',
      mainCurrencyId: 185,
    },
    {
      id: 171,
      name: 'Tanzania',
      countryCallingCode: '255',
      isoCode: 'TZ',
      mainCurrencyId: 182,
    },
    {
      id: 173,
      name: 'Togo',
      countryCallingCode: '228',
      isoCode: 'TG',
      mainCurrencyId: 258,
    },
    {
      id: 174,
      name: 'Tonga',
      countryCallingCode: '676',
      isoCode: 'TO',
      mainCurrencyId: 288,
    },
    {
      id: 175,
      name: 'Trinidad and Tobago',
      countryCallingCode: '1868',
      isoCode: 'TT',
      mainCurrencyId: 231,
    },
    {
      id: 176,
      name: 'Tunisia',
      countryCallingCode: '216',
      isoCode: 'TN',
      mainCurrencyId: 241,
    },
    {
      id: 177,
      name: 'Turkey',
      countryCallingCode: '90',
      isoCode: 'TR',
      mainCurrencyId: 179,
    },
    {
      id: 178,
      name: 'Turkmenistan',
      countryCallingCode: '993',
      isoCode: 'TM',
      mainCurrencyId: 178,
    },
    {
      id: 179,
      name: 'Uganda',
      countryCallingCode: '256',
      isoCode: 'UG',
      mainCurrencyId: 139,
    },
    {
      id: 180,
      name: 'Ukraine',
      countryCallingCode: '380',
      isoCode: 'UA',
      mainCurrencyId: 137,
    },
    {
      id: 181,
      name: 'UAE',
      countryCallingCode: '971',
      isoCode: 'AE',
      mainCurrencyId: 267,
    },
    {
      id: 182,
      name: 'Uruguay',
      countryCallingCode: '598',
      isoCode: 'UY',
      mainCurrencyId: 140,
    },
    {
      id: 183,
      name: 'Uzbekistan',
      countryCallingCode: '998',
      isoCode: 'UZ',
      mainCurrencyId: 138,
    },
    {
      id: 184,
      name: 'Venezuela',
      countryCallingCode: '58',
      isoCode: 'VE',
      mainCurrencyId: 192,
    },
    {
      id: 185,
      name: 'Vietnam',
      countryCallingCode: '84',
      isoCode: 'VN',
      mainCurrencyId: 260,
    },
    {
      id: 186,
      name: 'Yemen',
      countryCallingCode: '967',
      isoCode: 'YE',
      mainCurrencyId: 141,
    },
    {
      id: 188,
      name: 'Zimbabwe',
      countryCallingCode: '263',
      isoCode: 'ZW',
      mainCurrencyId: 225,
    },
    {
      id: 189,
      name: 'Zaire',
      countryCallingCode: '243',
      isoCode: 'ZR',
      mainCurrencyId: 156,
    },
    {
      id: 190,
      name: 'Zambia',
      countryCallingCode: '260',
      isoCode: 'ZM',
      mainCurrencyId: 259,
    },
    {
      id: 192,
      name: 'Serbia',
      countryCallingCode: '381',
      isoCode: 'RS',
      mainCurrencyId: 186,
    },
    {
      id: 193,
      name: 'Puerto Rico2',
      countryCallingCode: '1939',
      isoCode: 'PR',
      mainCurrencyId: 247,
    },
  ],
}
