import React, { useContext } from 'react'

import { LangContext } from '@/reducer'
import intl from 'react-intl-universal'

import bg from '../img/background.png'
import bg2 from '../img/background@2x.png'
import bg_ft from '../img/background_ft.png'
import bg_ft2 from '../img/background_ft@2x.png'

export default function Head() {
  const {
    state: { lang },
  } = useContext(LangContext)
  const isEn = lang === 'en-US'

  return (
    <div className="head_block">
      <div className="head_main wow fadeInUp">
        <h3 className="head_title">{intl.get('About us')}</h3>
        <p className="head_desc">{intl.get('about_desc1')}</p>
        <p className="head_desc">{intl.get('about_desc2')}</p>
      </div>
      {isEn ? (
        <img src={bg} srcSet={bg2} alt=""></img>
      ) : (
        <img src={bg_ft} srcSet={bg_ft2} alt=""></img>
      )}
    </div>
  )
}
