import React, { useEffect } from 'react'
import Head from './components/head'
import Trade from './components/trade'
import Elite from './components/elite'
import wow from 'wowjs'
import './index.less'

export default function MobileAbout() {
  useEffect(() => {
    setTimeout(() => {
      new wow.WOW().init()
    }, 300)
  }, [])
  return (
    <div className="about_block">
      <Head />
      <Trade />
      <Elite />
    </div>
  )
}
