import inIcon from '@/assets/img/in.svg'
import faceBookIcon from '@/assets/img/facebook.svg'
import twitterIcon from '@/assets/img/twitter.svg'
import insIcon from '@/assets/img/ins.svg'

export default {
  navList: [
    {
      text: 'home',
      path: '/mobile/home',
    },
    {
      text: 'download',
      path: '/mobile/download',
    },
    {
      text: 'about',
      path: '/mobile/about',
    },
  ],
  footList: [
    {
      text: 'download',
      path: '/mobile/download',
    },
    {
      text: 'about',
      path: '/mobile/about',
    },
    {
      text: 'Terms of Service',
      path: '/mobile/protocol?tagName=terms',
    },
    {
      text: 'Privacy Policy',
      path: '/mobile/protocol?tagName=privacy',
    },
  ],
  services: [
    {
      key: 'Customer Service',
      text: '(852) 2111 2666',
    },
    {
      key: 'Fax number',
      text: '(852) 2111 2620',
    },
    {
      key: 'Email',
      text: 'cs@premierqhk.com',
    },
    {
      key: 'Office',
      text: 'OfficeRoom',
    },
    {
      key: 'Office hours',
      text: 'Monday to Friday 9:00-18:00 （GMT+8',
    },
  ],
  friendLink: [
    {
      icon: inIcon,
      path: 'https://www.linkedin.com/company/69403666/admin/',
      name: 'in',
    },
    {
      icon: insIcon,
      path: 'https://www.instagram.com/premier.q.securities/',
      name: 'ins',
    },
    {
      icon: twitterIcon,
      path: 'https://twitter.com/premierqsecuri1',
      name: 'twitter',
    },
    {
      icon: faceBookIcon,
      path: 'https://www.facebook.com/premierqsecurities',
      name: 'facebook',
    },
  ],
}
