import React from 'react'
import { Image } from 'antd'
// import licenseImg from '@/assets/home/牌照@2x.png'

const Test = () => {
  return (
    <div>
      <Image src="https://s1.ax1x.com/2020/11/03/By6OFe.png" />
    </div>
  )
}

export default Test
