import React from 'react'

import intl from 'react-intl-universal'

const ListItem = ({ icon, desc }) => {
  return (
    <li className="list_item ">
      <img className="list_item_icon" src={icon} alt="" />
      <p className="list_item_text">{desc}</p>
    </li>
  )
}

export default function Why() {
  const whyList = [
    {
      icon: require('../img/icon1.svg'),
      desc: intl.get('Baidu'),
    },
    {
      icon: require('../img/icon2.svg'),
      desc: intl.get('well-established'),
    },
    {
      icon: require('../img/icon3.svg'),
      desc: intl.get('same day'),
    },
    {
      icon: require('../img/icon4.svg'),
      desc: intl.get('fluctuations'),
    },
  ]

  return (
    <div className="why_main">
      <h2 className="why_title  wow fadeInUp">
        {intl.get('Why invest in HK and US stocks')}?
      </h2>

      <ul className=" wow fadeInUp">
        {whyList.map((item, index) => (
          <ListItem key={index} {...item} />
        ))}
      </ul>
    </div>
  )
}
