import React, { useEffect } from 'react'
import intl from 'react-intl-universal'

import './style/step1.less'
import './style/step2.less'
import './style/step3.less'
import sgImg from '@/assets/about/gaosheng@2x.png'
import gxImg from '@/assets/about/guoxin@2x.png'
import swhyImg from '@/assets/about/shenwan@2x.png'
import zsImg from '@/assets/about/zhaoshang@2x.png'
import zgyhImg from '@/assets/about/china@2x.png'
import paImg from '@/assets/about/pingan@2x.png'
import txImg from '@/assets/about/tencent@2x.png'
import albbImg from '@/assets/about/alibaba@2x.png'
import wow from 'wowjs'

const PageStep1 = () => (
  <div className="about_step1">
    {/* <div
      style={{ height: '72px', background: 'transparent', width: '100%' }}
    ></div> */}
    <div className="step1_content">
      <h1 className="wow fadeInUp">{intl.get('About us')}</h1>
      <div className="wow fadeInUp" data-wow-delay="0.3s">
        <p>{intl.get('about_desc1')}</p>
        <p>{intl.get('about_desc2')}</p>
      </div>
    </div>
  </div>
)
const PageStep2 = () => (
  <div className="about_step2">
    <div className="step2_content">
      <div>
        <h1 className="wow fadeInUp">{intl.get('Trade Global Stocks')}</h1>
        <h3 className="wow fadeInUp" data-wow-delay="0.2s">
          {intl.get('Invest globally in one account')}
        </h3>
      </div>
      <div className="step2_cost wow fadeInUp" data-wow-delay="0.3s">
        <div className="cost_us cost_block">
          <h4>{intl.get('US Stocks')}</h4>
          {/* <main className="cost_main">
            <div className="cost_item">
              <p className="cost_item_title">{intl.get('Commission')}</p>
              <p className="cost_item_text">
                <span className="cost_item_amout">
                  {intl.get('0049 USD / share')}
                </span>
                <span> ,{intl.get('lowest')} </span>
                <span className="cost_item_amout">
                  {intl.get('$0.99 / pen')}
                </span>
              </p>
            </div>
            <div className="cost_item">
              <p className="cost_item_title">
                {intl.get('Platform Usage Fee')}
              </p>
              <p className="cost_item_text">
                <span className="cost_item_amout">
                  {intl.get('US $0.005/share')}
                </span>
                <span>,{intl.get('lowest')}</span>
                <span className="cost_item_amout">
                  {intl.get('$1.00 per transaction')}
                </span>
              </p>
            </div>
          </main> */}
          <main className="cost_main">
            <div className="cost_item">
              <p className="cost_item_title">{intl.get('Commission')}</p>
              <p className="cost_item_text">
                <span className="cost_item_amout">{intl.get('$0.0049')}</span>
                <span> {intl.get('per share')} </span>
                <span className="cost_item_amout">{intl.get('$0.99')}</span>
                <span> {intl.get('trading minimum')}</span>
              </p>
            </div>
            <div className="cost_item">
              <p className="cost_item_title">
                {intl.get('Platform Usage Fee')}
              </p>
              <p className="cost_item_text">
                <span className="cost_item_amout">{intl.get('$0. 005')}</span>
                <span> {intl.get('per share')}</span>
                <span className="cost_item_amout">{intl.get('$1.00')}</span>
                <span> {intl.get('per deal1')}</span>
              </p>
            </div>
          </main>
        </div>
        <div className="cost_hk cost_block">
          <h4>{intl.get('HK Stocks')}</h4>
          {/* <main className="cost_main">
            <div className="cost_item">
              <p className="cost_item_title">{intl.get('Commission')}</p>
              <p className="cost_item_text">
                <span className="cost_item_amout">0.029%*</span>
                <span> {intl.get('Transaction amount, minimum charge')}</span>
                <span>{intl.get('minimum')} </span>
                <span className="cost_item_amout"> {intl.get('HK $3')}</span>
              </p>
            </div>
            <div className="cost_item">
              <p className="cost_item_title">
                {intl.get('Platform Usage Fee')}
              </p>
              <p className="cost_item_text">
                <span className="cost_item_amout">
                  {intl.get('HK $15 / transaction')}
                </span>
                <span> , {intl.get('transaction volume')}</span>
                <span className="cost_item_amout">
                  {intl.get('Over HK $60000')}
                </span>
                <span>{intl.get('No charge')}</span>
              </p>
            </div>
          </main> */}
          <main className="cost_main">
            <div className="cost_item">
              <p className="cost_item_title">{intl.get('Commission')}</p>
              <p className="cost_item_text">
                <span> {intl.get('tradingvalue')} </span>
                <span className="cost_item_amout">0.029%</span>
                <span>{intl.get('minimum')} </span>
                <span className="cost_item_amout">HKD 3</span>
                <span> {intl.get('per deal')}</span>
              </p>
            </div>
            <div className="cost_item">
              <p className="cost_item_title">
                {intl.get('Platform Usage Fee')}
              </p>
              <p className="cost_item_text">
                <span className="cost_item_amout">{intl.get('HKD15')}</span>
                <span> {intl.get('trading volume')}</span>
                <span className="cost_item_amout"> 60,000 HKD</span>
                <span>{intl.get('free of charge')}</span>
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
)
const PageStep3 = () => (
  <div className="about_step3">
    <div className="team_elite">
      <h1 className="wow fadeInUp">{intl.get('Elite Team')}</h1>
      <p className="team_subtitle wow fadeInUp" data-wow-delay="0.2s">
        {intl.get('team_desc')}
      </p>
      <div className="team_list wow fadeInUp" data-wow-delay="0.3s">
        {[sgImg, gxImg, swhyImg, zsImg, zgyhImg, paImg, txImg, albbImg].map(
          (img, index) => (
            <div className="team_name" key={index}>
              <img src={img} alt="" />
            </div>
          )
        )}
        <div>
          <img src="" alt="" />
        </div>
      </div>
    </div>
  </div>
)
const About = () => {
  useEffect(() => {
    new wow.WOW().init()
  }, [])
  return (
    <div className="web_about">
      <PageStep1 />
      <PageStep2 />
      <PageStep3 />
    </div>
  )
}

export default About
