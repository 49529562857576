import 'rmc-notification/assets/index.css'
import Notification from 'rmc-notification'
import React from 'react'
import SuccessIcon from './img/ok.svg'
// import CleanIcon from '@/icons/clean'
import ErrorIcon from './img/err.svg'
import WarningIcon from './img/warn.svg'

import './index.less'
let notification = null
Notification.newInstance({}, (n) => (notification = n))
let messageInstance = null
// if (notification) {
//   console.log('ssssss===========')
// }
class Toast {
  duration = 4
  loadingText = '努力加载中'
  config(c) {
    this.duration = c.duration
    this.loadingText = c.loadingText
  }
  info(tip, duration) {
    if (messageInstance) {
      messageInstance.destroy()
      messageInstance = null
      notification = null
      Notification.newInstance({}, (n) => (notification = n))
    }
    notification.notice({
      content: (
        <div className="toast_body">
          <img src={WarningIcon} className="toast_success" />
          <div className="toast_tip">
            <p dangerouslySetInnerHTML={{ __html: tip }}></p>
          </div>
        </div>
      ),
      duration,
    })
    messageInstance = notification
  }
  success(tip, duration) {
    if (messageInstance) {
      messageInstance.destroy()
      messageInstance = null
      notification = null
      Notification.newInstance({}, (n) => (notification = n))
    }
    notification.notice({
      content: (
        <div className="toast_body">
          <img src={SuccessIcon} className="toast_success" />
          <div className="toast_tip">
            <p dangerouslySetInnerHTML={{ __html: tip }}></p>
          </div>
        </div>
      ),
      duration: duration || this.duration,
    })
    messageInstance = notification
  }
  fail(tip, duration) {
    // notification.removeNotice()
    if (messageInstance) {
      messageInstance.destroy()
      messageInstance = null
      notification = null
      Notification.newInstance({}, (n) => (notification = n))
    }
    notification.notice({
      content: (
        <div className="toast_body">
          <img src={ErrorIcon} className="toast_success" />
          <div className="toast_tip">
            <p dangerouslySetInnerHTML={{ __html: tip }}></p>
          </div>
        </div>
      ),
      duration: duration || this.duration,
    })
    messageInstance = notification
  }
  loading(loadingText) {
    notification.destroy()

    const key = Date.now()
    this.key = key
    notification.notice({
      content: (
        <div className="toast_body">
          {/* <LoadingIcon className="toast_loading" spin rotate={90} /> */}
          <div className="toast_tip">{loadingText || this.loadingText}</div>
        </div>
      ),
      duration: null,
      key,
    })
  }
  hide() {
    notification.removeNotice(this.key)
  }
}

export default Toast
