// 字符串转对象
export function pathToObject(str = '') {
  const pathCollection = {}

  if (str === '' || str === undefined) {
    return pathCollection
  }
  
  (str.split('&') || []).forEach(item => {
    const [key, value] = item.split('=')
    
    pathCollection[key] = value.trim()
  })

  return pathCollection
}