import React, { useContext } from 'react'

import { LangContext } from '@/reducer'
import intl from 'react-intl-universal'
import PhoneIcon from '../img/us.gif'
import PhoneIconFt from '../img/hk.gif'
import config from '@/utils/config'
import GoogleIcon from '../img/google-play.svg'
import AppStoreIcon from '../img/app-store.svg'

const ListItem = ({ text }) => {
  return (
    <li className="list_item">
      <i className="list_item_icon"></i>
      <p className="list_item_text">{text}</p>
    </li>
  )
}

export default function Download() {
  const {
    state: { lang },
  } = useContext(LangContext)
  const classsName =
    lang === 'en-US' ? 'download_main' : 'download_main download_main_ft'

  return (
    <div className={classsName}>
      <div className=" wow fadeInUp">
        <h1 className="title">
          {intl.get('Premier Q Mobile Trading Platform')}
        </h1>
        <p className="title_desc">
          {intl.get('Make investment easier through technology')}
        </p>
      </div>
      <ul className="list  wow fadeInUp">
        {(intl.get('download_list').split('@') || []).map((item, index) => {
          return <ListItem text={item} key={index} />
        })}
      </ul>

      {lang === 'en-US' ? (
        <img className="phoneIcon" src={PhoneIcon} alt="" />
      ) : (
        <img className="phoneIcon" src={PhoneIconFt} alt="" />
      )}

      <div className="download_btn">
        <div>
          <a href={config.android} className="btn ">
            <img className="btn_icon" src={GoogleIcon} alt="" />
            <span className="btn_text">Google Play</span>
          </a>
        </div>
        <div>
          <a href={config.ios} className="btn">
            <img className="btn_icon" src={AppStoreIcon} alt="" />
            <span className="btn_text">App Store</span>
          </a>
        </div>
      </div>
    </div>
  )
}
