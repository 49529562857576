import React, { useContext } from 'react'

import { LangContext } from '@/reducer'
import intl from 'react-intl-universal'

const FirstCard = () => {
  return (
    <div className="card">
      <div className="card_title ">{intl.get('US Stocks')}</div>

      <p className="first_mian">{intl.get('Commission')}</p>
      <p className="second_main">
        <span className="highlight">{intl.get('$0.0049')} </span>
        {intl.get('per share')}
        <span className="highlight"> {intl.get('$0.99')} </span>
        {intl.get('trading minimum')}
      </p>

      <p className="first_mian">{intl.get('Platform Usage Fee')}</p>
      <p className="second_main">
        <span className="highlight">{intl.get('$0. 005')} </span>
        {intl.get('per share')}
        <span className="highlight"> {intl.get('$1.00')} </span>
        {intl.get('per deal1')}
      </p>
    </div>
  )
}

const SecondCard = (props) => {
  return (
    <div className="card">
      <div className="card_title second_card_title">
        {intl.get('HK Stocks')}
      </div>

      <p className="first_mian">{intl.get('Commission')}</p>
      <p className="second_main">
        {intl.get('tradingvalue')}
        <span className="highlight">0.029%</span>
        {props.lang === 'en-US' ? (
          <>
            <span>,</span>
            <br />
          </>
        ) : (
          ''
        )}
        {intl.get('m minimum')}
        <span className="highlight"> HKD 3 </span>
        {intl.get('per deal')}
      </p>

      <p className="first_mian">{intl.get('Platform Usage Fee')}</p>
      <p className="second_main">
        <span className="highlight">{intl.get('HKD15')} </span>
        {intl.get('m pre trading volume')}
        {props.lang === 'en-US' ? <br /> : ''}
        {intl.get('m next trading volume')}
        <span className="highlight"> 60,000 HKD </span>
        {intl.get('free of charge')}
      </p>
    </div>
  )
}

export default function Trade() {
  const {
    state: { lang },
  } = useContext(LangContext)

  return (
    <div className="trade_block">
      <div className="wow fadeInUp">
        <h2 className="title ">{intl.get('Trade Global Stocks')}</h2>
        <p className="title_desc">
          {intl.get('Invest globally in one account')}
        </p>
      </div>
      <div className="wow fadeInUp">
        <FirstCard />
        <SecondCard lang={lang} />
      </div>
    </div>
  )
}
