import React from 'react'
import Bg from '@/assets/404/image.svg'
import intl from 'react-intl-universal'
import './index.less'
const NotFound = () => {
  return (
    <div className="not_found" style={{}}>
      <img src={Bg} alt="" />
      <p>{intl.get('notfound')}</p>
    </div>
  )
}

export default NotFound
