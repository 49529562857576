import React, { useState, useRef, useLayoutEffect, useContext } from 'react'
import './index.less'
import { NavLink, withRouter, Link } from 'react-router-dom'
import config from '@/utils/config.js'
import { Select } from 'antd'
import intl from 'react-intl-universal'
import downIcon from '@/assets/sign/下拉 (1).svg'
import { LangContext, UPDATE_LANG } from '@/reducer'

const { Option } = Select
const fixedMenu = ['/sign', '/protocol/terms', '/protocol/privacy']
const LayoutHeader = (props) => {
  const {
    location: { pathname },
  } = props
  const {
    state: { lang },
    dispatch,
  } = useContext(LangContext)
  // console.log('lang', lang)
  const navRef = useRef(null)
  const [isFixed, setIsFixed] = useState(false)
  let logo, fixedLogo
  if (lang === 'en-US') {
    logo = require('@/assets/home/logo_en_top.svg')
    fixedLogo = require('@/assets/home/logo_en_top_color.svg')
  } else {
    logo = require('@/assets/img/logo.svg')
    fixedLogo = require('@/assets/img/fixed_log.svg')
  }

  useLayoutEffect(() => {
    setIsFixed(false)
    if (!fixedMenu.includes(pathname)) {
      window.addEventListener('scroll', scroll, false)
    }
    return () => {
      window.removeEventListener('scroll', scroll, false)
    }
  }, [pathname])
  const scroll = () => {
    fixedNavBar()
  }
  // console.log('pathname', pathname)
  const fixedNavBar = () => {
    const top = document.body.scrollTop || document.documentElement.scrollTop
    // setScrollTop(top)
    if (top !== 0) {
      navRef.current.classList.add('fixed')
      setIsFixed(true)
    } else {
      navRef.current.classList.remove('fixed')
      setIsFixed(false)
    }
  }
  const handleSelectLocale = (value) => {
    // setLang(value)
    dispatch({
      type: UPDATE_LANG,
      lang: value,
    })
    localStorage.setItem('lang_type', value)
    window.location.reload()
  }

  return (
    <div>
      <div
        className={
          fixedMenu.includes(pathname) ? 'web_header fixed' : 'web_header'
        }
        ref={navRef}
      >
        <div className="header_container">
          <Link to="/">
            <img
              src={isFixed || fixedMenu.includes(pathname) ? fixedLogo : logo}
              alt="logo"
              className="logo"
            />
          </Link>
          <ul className="header_nav">
            {config.navConfig.map((nav, index) => {
              return (
                <li key={index}>
                  <NavLink to={nav.path} className="nav_span">
                    <span>{intl.get(nav.name)}</span>
                  </NavLink>
                </li>
              )
            })}
          </ul>
          <div className="header_right">
            <button className="header_sign">
              <Link to="/sign">{intl.get('sign')}</Link>
            </button>
            <Select
              defaultValue={lang}
              className="header_lang"
              bordered={false}
              dropdownClassName="select_dropdown"
              suffixIcon={
                <img
                  src={downIcon}
                  style={{
                    fontSize: 0,
                    verticalAlign: 'top',
                    width: '12px',
                    height: '12px',
                  }}
                />
              }
              onChange={handleSelectLocale}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              <Option value="en-US">English</Option>
              <Option value="zh-CN">中文繁体</Option>
            </Select>
          </div>
        </div>
      </div>
      <div
      // style={{ height: '72px', width: '100%', background: 'transparent' }}
      ></div>
    </div>
  )
}

export default withRouter(LayoutHeader)
