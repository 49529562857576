export default {
  navConfig: [
    {
      name: 'home',
      path: '/home',
    },
    {
      name: 'download',
      path: '/download',
    },
    {
      name: 'about',
      path: '/about',
    },
  ],
  footerMenu: [
    {
      name: 'download',
      path: '/download',
    },
    {
      name: 'about',
      path: '/about',
    },
    {
      name: 'terms',
      path: '/protocol/terms',
    },
    {
      name: 'privacy',
      path: '/protocol/privacy',
    },
  ],
  //下载地址
  android:
    'https://gateway.premierqstock.com:1446/api/user-service/v1/user/officeSite/getPremierqApp',
  ios: 'https://apps.apple.com/cn/app/%E4%B9%BE%E7%AB%8B%E4%BA%A8/id1535295622',
  //客服地址
  live:
    'https://v88.live800.com/live800/chatClient/chatbox.jsp?companyID=1456619&configID=16979&jid=6675700485&s=1',
}
