import React from 'react'
import intl from 'react-intl-universal'
import { withRouter } from 'react-router-dom'
import './index.less'
const ProtocolItem = (props) => {
  const {
    location: { pathname },
  } = props
  const name = pathname.split('/').splice(-1)[0]

  //隐私政策
  const privacy = [intl.get('privacyContent')]
  //服务条款
  const terms = [intl.get('termsContent')]
  const protocolContent = { privacy, terms }
  return (
    <div className="web_protocol_item">
      <div className="protocol_content">
        {protocolContent[name].map((item, index) => (
          <div
            key={index}
            className="text"
            dangerouslySetInnerHTML={{ __html: `<p> ${item}</p>` }}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default withRouter(ProtocolItem)
