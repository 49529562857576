/**
 * 大陆手机号码正则
 */
export const mainlandReg = /^1[3-9]\d{9}$/

/**
 * 香港特别行政区号码正则
 */
export const hkPhoneReg = /(5[1234569]\d{6}|6\d{7}|9[0-8]\d{6})$/

/**
 * 澳门特别行政区号码正则
 */
export const macaoPhoneReg = /^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/

/**
 * 其他号码正则
 */
export const otherPhoneReg = /\d$/

/**
 * 邮箱校验正则
 */
export const emailReg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g
