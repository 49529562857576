import React from 'react'

import intl from 'react-intl-universal'

const Terms = () => {
  return <div className="terms_block">
    <h1 className="title">{intl.get('Terms of Service')}</h1>

    <div className="content">
      {[intl.get('termsContent')].map((item, index) => (
        <div
          key={index}
          className="text"
          dangerouslySetInnerHTML={{ __html: `<p> ${item}</p>` }}
        ></div>
      ))}
    </div>
  </div>
}

export default Terms
