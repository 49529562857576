import React from 'react'

import intl from 'react-intl-universal'

const Privacy = () => {
  return <div className="privacy_block">
    <h1 className="title">{intl.get('Privacy Policy')}</h1>

    <div className="content privacy_content">
      {[intl.get('privacyContent')].map((item, index) => (
        <div
          key={index}
          className="text"
          dangerouslySetInnerHTML={{ __html: `<p> ${item}</p>` }}
        ></div>
      ))}
    </div>
  </div>
}

export default Privacy
