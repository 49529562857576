import Icon from '@ant-design/icons'
import React from 'react'

const appSvg = () => (
  <svg width="30" height="26" xmlns="http://www.w3.org/2000/svg">
    <title>app-store</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fill="currentColor" id="首页">
          <g id="background1">
            <g id="编组-20备份">
              <g id="编组-8">
                <g id="app-store">
                  <path
                    id="Fill-1"
                    d="m14.86579,2.54008l0.79661,-1.33322c0.46477,-0.86667 1.52678,-1.13338 2.38989,-0.66672c0.8631,0.46672 1.12871,1.53343 0.66393,2.39994l-7.5682,13l5.44372,0c1.72615,0 2.72193,2.06666 1.99176,3.53332l-15.86699,0c-0.99578,0 -1.72616,-0.79994 -1.72616,-1.73337c0,-0.99995 0.79682,-1.73323 1.72616,-1.73323l4.44814,0l5.70932,-9.99996l-1.79239,-3.13332c-0.46477,-0.86667 -0.19916,-1.93338 0.66389,-2.4001c0.86289,-0.46671 1.92516,-0.2 2.38994,0.66672l0.73038,1.39994zm-6.7718,18.73327l-1.65972,2.93332c-0.46477,0.86671 -1.52678,1.13343 -2.38988,0.66671c-0.86311,-0.46671 -1.12872,-1.53343 -0.66394,-2.39988l1.26138,-2.20015c1.39411,-0.46672 2.58905,-0.13323 3.45216,1zm14.47267,-5.33327l4.58082,0c0.99603,0 1.72621,0.8 1.72621,1.73338c0,1 -0.79667,1.73323 -1.72621,1.73323l-2.52256,0l1.72595,3.00004c0.46477,0.86671 0.19916,1.93337 -0.66394,2.39988c-0.86285,0.46672 -1.92512,0.2 -2.38989,-0.66645c-2.85466,-4.99998 -5.04559,-8.7333 -6.4397,-11.26668c-1.46055,-2.53332 -0.39833,-5.0667 0.5975,-5.93341c1.12866,2.06665 2.85466,5.06675 5.11182,9.00001z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const appIcon = (props) => <Icon component={appSvg} {...props} />

export default appIcon
