import Icon from '@ant-design/icons'
import React from 'react'

const checkoutSvg = () => (
  <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
    <title>选中</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="402"
        width="582"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="页面-1">
        <g fill="#422CEA" id="注册页-补充">
          <g id="编组-27">
            <g id="编组-5">
              <g id="编组-2">
                <g id="编组-4">
                  <path
                    transform="rotate(-45 7.778167724609376,4) translate(-471.7781677246094,-8) "
                    id="选中"
                    d="m473.05634,16c-0.27614,0 -0.5,-0.22386 -0.5,-0.5l0,-7c0,-0.27614 0.22386,-0.5 0.5,-0.5l1,0c0.27615,0 0.5,0.22386 0.5,0.5l0,5.5l11.5,0c0.27615,0 0.5,0.22386 0.5,0.5l0,1c0,0.27614 -0.22385,0.5 -0.5,0.5l-13,0z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const checkoutIcon = (props) => <Icon component={checkoutSvg} {...props}></Icon>

export default checkoutIcon
