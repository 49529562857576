import React from 'react'
import { renderRoutes } from 'react-router-config'
import LayoutHeader from 'mobile/component/Header'
import LayoutFooter from 'mobile/component/Footer'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import intl from 'react-intl-universal'
import config from '@/utils/config'
import './index.less'
const FooterShop = () => {
  return (
    <div className="home_footer_shop ">
      <Link to="/mobile/sign">
        <button>{intl.get('mobile_Sign Up')}</button>
      </Link>
      <a href={config.live}>
        <button className="livechat">{intl.get('home live chat')}</button>
      </a>
    </div>
  )
}
const MobileLayout = (props) => {
  const { route, location } = props
  // console.log(route)
  return (
    <div>
      {location.pathname === '/mobile/sign' ? '' : <LayoutHeader />}
      {/* <div className={location.pathname !== '/mobile/sign' ? 'container' : ''}> */}
      {renderRoutes(route.routes)}
      {/* </div> */}
      <LayoutFooter />
      {location.pathname === '/mobile/home' && (
        // <div className="home_placeholder"></div>
        <FooterShop />
      )}
    </div>
  )
}

export default React.memo(withRouter(MobileLayout))
