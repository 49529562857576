import React from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '@/layout'
import Home from '@/pages/Home'
import About from '@/pages/About'
import Download from '@/pages/Download'
import Sign from '@/pages/Sign/index'
import Protocol from '@/pages/Protocol'
import NotFound from '@/pages/NotFound'
import TestPage from '@/pages/test'
// import MobileLayout from '@/mobile/layout'
// import MobileRoutes from '@/mobile/route'
export default [
  {
    // path: '/',
    component: Layout,
    routes: [
      {
        path: '/',
        exact: true,
        render: () => <Redirect to={'/home'} />,
      },
      {
        path: '/home',
        component: Home,
      },
      {
        path: '/download',
        component: Download,
      },
      {
        path: '/about',
        component: About,
      },
      {
        path: '/sign',
        component: Sign,
      },
      {
        path: '/protocol',
        component: Protocol,
      },
      {
        path: '/404',
        component: NotFound,
      },
      {
        path: '/test',
        component: TestPage,
      },
      {
        path: '*',
        render: () => <Redirect to={'/404'} />,
      },
    ],
  },
  // {
  //   path: '/mobie',
  //   component: MobileLayout,
  //   routes: MobileRoutes,
  // },

  // {
  //   path: '/404',
  //   component: NotFound,
  // },
  // {
  //   path: '*',
  //   render: () => <Redirect to={'/404'} />,
  // },
]
