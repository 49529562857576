import React, { useEffect, useContext } from 'react'
import QrCodeStore from '@/components/QrCodeStore'
import intl from 'react-intl-universal'
import './style/step1.less'
import gifPictureEn from '@/assets/download/en.gif'
import gifPictureZh from '@/assets/download/zh.gif'
import SafeIcon from '@/icons/safe'
import SmileIcon from '@/icons/smile'
import ToneIcon from '@/icons/tone'
import LinedIcon from '@/icons/outLined'
import { LangContext } from '@/reducer'
import './style/step2.less'
import wow from 'wowjs'

const Download = () => {
  useEffect(() => {
    new wow.WOW().init()
  }, [])
  const PageStep1 = () => {
    const {
      state: { lang },
    } = useContext(LangContext)
    return (
      <div className="down_step1">
        <div
          style={{ height: '72px', background: 'transparent', width: '100%' }}
        ></div>
        <div className="step1_content">
          <QrCodeStore />
          <img
            className="step1_pic"
            src={lang === 'en-US' ? gifPictureEn : gifPictureZh}
            alt="交易"
          />
        </div>
      </div>
    )
  }
  const PageStep2 = () => {
    const stocksList = [
      {
        icon: LinedIcon,
        desc: intl.get('Baidu'),
      },
      {
        icon: SafeIcon,
        desc: intl.get('well-established'),
      },
      {
        icon: ToneIcon,
        desc: intl.get('same day'),
      },
      {
        icon: SmileIcon,
        desc: intl.get('fluctuations'),
      },
    ]
    return (
      <div className="down_step2">
        <h1 className="wow fadeInUp">
          {intl.get('Why invest in HK and US stocks')}?
        </h1>
        <div className="step2_list wow fadeInUp" data-wow-delay="0.3s">
          {stocksList.map((item, index) => (
            <div className="step2_item" key={index}>
              <item.icon />
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className="web_download">
      <PageStep1 />
      <PageStep2 />
    </div>
  )
}

export default Download
