import React, { useState, useEffect } from 'react'
import './global/App.less'
// import '@/assets/style/normalize.css'
import { BrowserRouter, withRouter } from 'react-router-dom'
// import routes from 'mobile/route/index.js'
import { renderRoutes } from 'react-router-config'
import intl from 'react-intl-universal'
import ScrollTop from '@/components/ScrollTop/index'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Lang } from '@/reducer'
// import { LangContext } from '@/reducer'
import 'animate.css'
import desktopRoute from './route/index'
import mobileRoute from 'mobile/route/index.js'
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
const routes = isMobile ? mobileRoute : desktopRoute

const locales = {
  'en-US': require('./locales/en-US.json'),
  'zh-CN': require('./locales/zh-CN.json'),
}

const Routes = withRouter(({ location }) => (
  <TransitionGroup className={'router-wrapper'}>
    <CSSTransition
      timeout={300}
      classNames={'slide'}
      key={location.pathname}
      unmountOnExit={true}
    >
      <Lang>
        <ScrollTop>{renderRoutes(routes)}</ScrollTop>
      </Lang>
    </CSSTransition>
  </TransitionGroup>
))

function App() {
  const [lang, setLang] = useState(localStorage.getItem('lang_type') || 'en-US')
  // const {
  //   state: { lang },
  // } = useContext(LangContext)
  const [initDone, setInitDone] = useState(false)
  useEffect(() => {
    loadLocales()
  }, [lang])
  useEffect(() => {
    window.onbeforeunload = function () {
      document.documentElement.scrollTop = 0 //ie下
      document.body.scrollTop = 0 //非ie
    }
    // const scrollToTop = () => {
    //   const c = document.documentElement.scrollTop || document.body.scrollTop
    //   // if (c > 0) {
    //   //   window.requestAnimationFrame(scrollToTop)
    //   //   window.scrollTo(0, c - c / 8)
    //   // }
    //   // window.requestAnimationFrame(scrollToTop)
    //   if (c > 0) {
    //     window.requestAnimationFrame(scrollToTop)
    //     window.scrollTo(0, 0)
    //   }
    // }
    // scrollToTop()
  }, [])
  const loadLocales = () => {
    intl
      .init({
        currentLocale: lang,
        locales,
      })
      .then(() => {
        setInitDone(true)
      })
  }
  return (
    <BrowserRouter>
      {/* <ScrollTop>{renderRoutes(routes)}</ScrollTop> */}
      <Routes />
    </BrowserRouter>
  )
}

export default App
