import React, { useState, useEffect, useContext } from 'react'
import './style/head.less'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import config from 'mobile/config'
import { Collapse } from 'antd'
import { LangContext, UPDATE_LANG } from '@/reducer'
import intl from 'react-intl-universal'
import xiala from './img/shouqi.svg'

const navIcon = require('./img/nav.svg')
const closeIcon = require('./img/close.svg')
const enLogo = require('@/assets/home/logo_en_top.svg')
const zhLogo = require('@/assets/img/logo.svg')
const { Panel } = Collapse
const MobileHeader = ({ location }) => {
  const { pathname } = location
  // console.log(location)
  const [top, setTop] = useState(0)
  const [visible, setVisible] = useState(false)
  const [iconStyle, setIconStyle] = useState({})
  const {
    state: { lang },
    dispatch,
  } = useContext(LangContext)
  const setScrollTop = (e) => {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop
    setTop(scrollTop)
  }
  const addCallBack = () => {
    setScrollTop()
  }
  useEffect(() => {
    window.addEventListener('scroll', addCallBack)
    return () => {
      window.removeEventListener('scroll', addCallBack)
    }
  }, [pathname])

  useEffect(() => {
    if (visible) {
      console.log(document.getElementsByTagName('body')[0])
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      document.getElementsByTagName('body')[0].style.touchAction = 'none'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = ''
      document.getElementsByTagName('body')[0].style.touchAction = ''
    }
  }, [visible])

  const showDrawer = () => {
    setVisible(!visible)
  }
  const handleSelectLocale = (value) => {
    return () => {
      setVisible(false)
      setTimeout(() => {
        dispatch({
          type: UPDATE_LANG,
          lang: value,
        })
        localStorage.setItem('lang_type', value)
        window.location.reload()
      }, 300)
    }
  }
  const logo = lang === 'en-US' ? enLogo : zhLogo
  return (
    <div
      className={top > 0 ? 'mobile_head black' : 'mobile_head'}
      style={{ overflow: visible ? 'inherit' : 'hidden' }}
    >
      <Link to="/mobile/home">
        <img src={logo} alt="" className="mobile_logo" />
      </Link>
      <div className="mobile_nav" onClick={showDrawer}>
        <img src={visible ? closeIcon : navIcon} alt="" />
        {/* <svg id="hamburger" className="Header__toggle-svg" viewBox="0 0 40 40">
          <g
            stroke="#fff"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path id="top-line" d="M10,10 L50,10 Z"></path>
            <path id="middle-line" d="M10,20 L50,20 Z"></path>
            <path id="bottom-line" d="M10,30 L50,30 Z"></path>
          </g>
        </svg> */}
      </div>
      <div
        className="mobile_nav_mask "
        style={visible ? { transform: 'translateX(0)' } : {}}
      >
        {config.navList.map((item) => {
          return (
            <div
              className={
                pathname === item.path ? 'active_nav nav_item' : 'nav_item'
              }
              key={item.path}
            >
              <Link to={item.path}>
                <span>{intl.get(item.text)}</span>
              </Link>
            </div>
          )
        })}
        <Collapse
          defaultActiveKey={['1']}
          bordered={false}
          expandIconPosition="right"
          ghost={true}
          onChange={(active) =>
            active.length
              ? setIconStyle({
                  transform: 'rotate(0deg)',
                })
              : setIconStyle({ transform: 'rotate(180deg)' })
          }
          expandIcon={() => (
            <div className="coll_icon">
              <img src={xiala} style={iconStyle} />
            </div>
          )}
          className="collapse"
        >
          <Panel header={intl.get('Language')} key="1">
            <div className="nav_item" onClick={handleSelectLocale('en-US')}>
              <span
                style={
                  lang === 'en-US' ? { color: '#422cea' } : { color: '#131313' }
                }
              >
                English
              </span>
            </div>
            <div className="nav_item" onClick={handleSelectLocale('zh-CN')}>
              <span
                style={
                  lang === 'zh-CN' ? { color: '#422cea' } : { color: '#131313' }
                }
              >
                中文繁体
              </span>
            </div>
          </Panel>
        </Collapse>
      </div>
      {/* <Drawer
        placement="right"
        closable={false}
        className="mobile_nav_mask"
        onClose={onClose}
        visible={visible}
      >
        {config.navList.map((item) => {
          return (
            <div className="nav_item" key={item.path}>
              <Link to={item.path}>
                <span>{item.text}</span>
              </Link>
            </div>
          )
        })}
      </Drawer> */}
    </div>
  )
}

export default withRouter(MobileHeader)
