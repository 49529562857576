import React, { useMemo, useContext } from 'react'

import { LangContext } from '@/reducer'
import intl from 'react-intl-universal'

const CardItem = ({ src, src2 }) => {
  return (
    <li className="card_item">
      <img src={src} alt="" srcSet={src2} />
    </li>
  )
}

export default function Elite() {
  const {
    state: { lang },
  } = useContext(LangContext)
  const classsName =
    lang === 'en-US' ? 'elite_block' : 'elite_block elite_block_ft'
  const img = useMemo(() => {
    return [
      'gaosheng',
      'guoxin',
      'shenwan',
      'zhaoshang',
      'china',
      'pingan',
      'tencent',
      'alibaba',
    ].map((item) => {
      return {
        src: require(`../img/${item}.png`),
        src2: require(`../img/${item}@2x.png`),
      }
    })
  })

  return (
    <div className={classsName}>
      <div className="wow fadeInUp">
        <h2 className="title ">{intl.get('Elite Team')}</h2>
        <p className="main">{intl.get('team_desc')}</p>
      </div>

      <ul className="card_list wow fadeInUp">
        {img.map((item, index) => {
          return <CardItem {...item} key={index} />
        })}
      </ul>
    </div>
  )
}
